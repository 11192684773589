import React from "react";
import { InfoIcon } from "./Icon";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";

const Component = ({
    imagePreviewIds,
    description,
}:{
    imagePreviewIds?: string[];
    description?: MultiLanguageString;
}) => {

    const [isHovering, setIsHovering] = React.useState(false);
    const ref = React.useRef(null);

    if((!description || description.getLanguages().length === 0) && (!imagePreviewIds || imagePreviewIds.length === 0)) return null;
    const showDescription = description?.getLanguages() && description.getLanguages().length > 0 && description.getLanguages()[0].getValue();

    return (
        <div
            ref={ref}
            className="inline relative px-2 mx-2 py-1 border border-solid border-light-gray bg-white hover:bg-light-gray rounded-sm"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <InfoIcon />
            {isHovering && <div
                className="fixed min-w-52 p-2 rounded-sm border border-solid border-light-gray bg-white z-10 drop-shadow-md max-w-[42rem]"
                style={{
                    top: ref.current?.getBoundingClientRect().top + 30,
                    left: ref.current?.getBoundingClientRect().left,
                }}
            >
                {imagePreviewIds?.length > 0 && <div className="flex flex-wrap justify-start gap-2">
                    {imagePreviewIds.map((x) => <a className={`block ${showDescription ? "mb-2" : ""}`} key={x} href={`/api/hotec/publicdownload/${x}`}>
                        <img
                            src={`/api/hotec/publicdownload/${x}`}
                            className="w-80 rounded-sm"
                        />
                    </a>)}
                </div>}
                {showDescription && <p className="p-0 m-0">{description.getLanguages()[0].getValue()}</p>}
            </div>}
        </div>
    );
}

export default Component;