import {isValidBoolean, isValidNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface ISupplement extends DataController {
    getTitle(): MultiLanguageString;
    setTitle(title: MultiLanguageString): void | Promise<void>;
    getDescription(): MultiLanguageString;
    setDescription(description: MultiLanguageString): void | Promise<void>;
    getPrice(): number;
    setPrice(price: number): void | Promise<void>;
    getMaxPieces(): number;
    setMaxPieces(maxPieces: number): void | Promise<void>;
    getImagePreviewIds(): string[];
    setImagePreviewIds(newImagePreviewIds: string[]): void | Promise<void>;
}

export default class Supplement extends DataController implements ISupplement {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;

    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    @DataController.dataProperty()
    private price: number;

    @DataController.dataProperty()
    private maxPieces: number;

    @DataController.dataProperty()
    private imagePreviewIds: string[];

    public constructor(
        title: MultiLanguageString,
        description: MultiLanguageString,
        price: number,
        maxPieces: number,
        imagePreviewIds: string[] = [],
    ){
        super();
        this.setTitle(title);
        this.setDescription(description);
        this.setPrice(price);
        this.setMaxPieces(maxPieces);
        this.setImagePreviewIds(imagePreviewIds);
    }

    public getImagePreviewIds(): string[] {
        return this.imagePreviewIds;
    }

    public setImagePreviewIds(newImagePreviewIds: string[]): void {
        this.imagePreviewIds = newImagePreviewIds;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getMaxPieces(): number {
        return this.maxPieces;
    }

    public getPrice(): number {
        return this.price;
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public setDescription(description: MultiLanguageString): void {
        this.description = description;
    }

    public setMaxPieces(maxPieces: number): void {
        if(!isValidNumber(maxPieces)) { // TODO: should this be positive integers only?
            throw VALUE_EXCEPTION;
        }
        this.maxPieces = maxPieces;
    }

    public setPrice(price: number): void {
        if(!isValidNumber(price)) {
            throw VALUE_EXCEPTION;
        }
        this.price = Number(price);
    }

    public setTitle(title: MultiLanguageString): void {
        this.title = title;
    }

    public clone(): Supplement{
        return new Supplement(
            this.title.clone(),
            this.description.clone(),
            this.price,
            this.maxPieces,
            [...this.imagePreviewIds],
        )
    }


}
