import * as React from "react";
import { connect } from "react-redux";
import { loadSpindleThreadTypes } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import StaircaseType from "../../../../../shared/datastructures/staircasedata/StaircaseType";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import SpindleThreadTypeAPI from "../../apicontroller/staircasedata/SpindleThreadTypeAPI";
import SpindleThreadType from "../../../../../shared/datastructures/staircasedata/SpindleThreadType";
import InfoScreen from "../../components/InfoScreen";
import { t } from "ttag";
import ImagePreviewUploadZone from "../../components/ImagePreviewUploadZone";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class SpindleThreadTypeSettings extends React.Component<any, any> {

    public spindleThreadTypesToSelectItems() {
        return toSelectItems<SpindleThreadTypeAPI>("spindlethreadtype", this.props.globalInformation.spindleThreadTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => SpindleThreadTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<SpindleThreadType> {
        return new SpindleThreadType(new MultiLanguageString(), new MultiLanguageString());
    }

    private async create(data: SpindleThreadType) {
        await SpindleThreadTypeAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Modelspijlen"
            selectItems={this.spindleThreadTypesToSelectItems()}

            reload={loadSpindleThreadTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: SpindleThreadTypeAPI) => SpindleThreadTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een modelspijl.`} image={"/cdn/images/pole.svg"}/>}
        />;
    }


    private renderEdit(a: SpindleThreadTypeAPI, setSelected: (i: SpindleThreadTypeAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => SpindleThreadTypeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => SpindleThreadTypeAPI.clone(x), setSelected.bind(this)),
            <ImagePreviewUploadZone
                imagePreviewIds={a.getImagePreviewIds()}
                onChange={(imagePreviewIds: string[]) => {
                    const cl: SpindleThreadTypeAPI = SpindleThreadTypeAPI.clone(a);
                    cl.getData().setImagePreviewIds(imagePreviewIds);
                    setSelected(cl);
                }}
            />,
        );
    }

    private renderCreate(a: SpindleThreadType, setSelected: (i: SpindleThreadType) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <ImagePreviewUploadZone
                imagePreviewIds={a.getImagePreviewIds()}
                onChange={(imagePreviewIds: string[]) => {
                    const cl: SpindleThreadType = a.clone();
                    cl.setImagePreviewIds(imagePreviewIds);
                    setSelected(cl);
                }}
            />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        imagePreviewMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze modelspijl."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Afbeeldingen"
                        description="Upload afbeeldingen waarop deze modelspijl te zien is."
                        topMost={false}
                    >
                        {imagePreviewMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze modelspijl."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
