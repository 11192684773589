import {isValidNumber, isValidPositiveInteger} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface IStaircaseType extends DataController {
    getTreadPrice(): number;
    getHandrailPrice(): number;
    getInstallationFactor(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getNumberOfPoles(): number;
    getImagePreviewIds(): string[];
    setTreadPrice(newPrice: number): void | Promise<void>;
    setHandrailPrice(newPrice: number): void | Promise<void>;
    setInstallationFactor(newFactor: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setNumberOfPoles(numberOfPoles: number): void | Promise<void>;
    setImagePreviewIds(newImagePreviewIds: string[]): void | Promise<void>;
}

export default class StaircaseType extends DataController implements IStaircaseType {

    @DataController.dataProperty()
    private treadPrice: number;
    @DataController.dataProperty()
    private handrailPrice: number;
    @DataController.dataProperty()
    private installationFactor: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private numberOfPoles: number;
    @DataController.dataProperty()
    private imagePreviewIds: string[];

    public constructor(
        treadPrice: number,
        handrailPrice: number,
        installationFactor: number,
        title: MultiLanguageString,
        description: MultiLanguageString,
        numberOfPoles: number,
        imagePreviewIds: string[] = [],
    ){

        super();
        this.setTreadPrice(treadPrice);
        this.setHandrailPrice(handrailPrice);
        this.setInstallationFactor(installationFactor);
        this.setTitle(title);
        this.setDescription(description);
        this.setNumberOfPoles(numberOfPoles);
        this.setImagePreviewIds(imagePreviewIds);
    }

    public getImagePreviewIds(): string[] {
        return this.imagePreviewIds;
    }

    public setImagePreviewIds(newImagePreviewIds: string[]): void {
        this.imagePreviewIds = newImagePreviewIds;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getNumberOfPoles(): number {
        return this.numberOfPoles;
    }

    public getHandrailPrice(): number {
        return this.handrailPrice;
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getTreadPrice(): number {
        return this.treadPrice;
    }

    public getInstallationFactor(): number{
        return this.installationFactor;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setNumberOfPoles(numberOfPoles: number): void {
        if(!isValidPositiveInteger(numberOfPoles))
            throw VALUE_EXCEPTION;    
        this.numberOfPoles = numberOfPoles;
    }

    public setHandrailPrice(newPrice: number): void {
        if(!isValidNumber(newPrice)) {
            throw VALUE_EXCEPTION;
        }
        this.handrailPrice = Number(newPrice);
    }

    public setInstallationFactor(newInstallationFactor: number): void {
        if(!isValidNumber(newInstallationFactor)){
            throw VALUE_EXCEPTION;
        }
        this.installationFactor = Number(newInstallationFactor);
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setTreadPrice(newPrice: number): void {
        if(!isValidNumber(newPrice)) {
            throw VALUE_EXCEPTION;
        }
        this.treadPrice = Number(newPrice);
    }

    public clone(): StaircaseType{
        return new StaircaseType(
            this.getTreadPrice(),
            this.getHandrailPrice(),
            this.getInstallationFactor(),
            this.getTitle().clone(),
            this.getDescription().clone(),
            this.getNumberOfPoles(),
            [...this.getImagePreviewIds()],
        );
    }

}
