import * as React from "react";
import { connect, DispatchProp } from "react-redux";

import { addArticleTreatmentColor, setBasicInformation, setArticleTreatment, disableTreatment, removeArticleTreatmentColor } from "../../../actions/articleCreationActions";
import StepTemplate from "../StepTemplate";

import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import SelectItem from "webc-reactcore/src/js/components/mainlayout/PortalSelectItem";
import SubTitle from "../../../components/SubTitle";

import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import * as PortalSelectStyle from "../../../../css/PortalSelect.css";
import * as rightFloatingMenuItemStyle from "../../../../css/rightFloatingMenuItemStyle.css";
import { inn } from "orbiter-core/src/basic";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import TreatmentInput from "../../../../../../shared/datastructures/articleinput/TreatmentInput";
import { ITreatmentInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import { INPUT_NOT_DEFINED } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import { t } from "ttag";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import DeleteSelectItem from "../../../components/DeleteSelectItem";
import ImagePreview from "../../../components/ImagePreview";

@(connect((store: any) => {
    return { article: { ...store.articleCreation }, quote: { ...store.quoteCreation } };
}) as any)
export default class Treatment extends React.Component<{ article: IArticleCreationState, quote: IQuoteCreationState } & DispatchProp, { validity: any }> {

    public setBasicInformation(info) {
        this.props.dispatch(setBasicInformation(info));
    }

    private updateArticleTreatment(treatment: TreatmentInput) {
        this.props.dispatch(setArticleTreatment(treatment));
    }

    private addArticleTreatmentColor(color: string) {
        this.props.dispatch(addArticleTreatmentColor(color));
    }

    private removeArticleTreatmentColor(color: string) {
        this.props.dispatch(removeArticleTreatmentColor(color));
    }

    private getArticleTreatmentInput(): TreatmentInput {
        return TreatmentInput.from(this.props.article.article.treatment)
    }

    private getArticleTreatment(): ITreatmentInput {
        return this.props.article.article.treatment;
    }

    public treatmentTypesToSelectItems() {
        const list = [];
        for (const treatmentType of this.props.quote.availableTreatmentTypes) {
            list.push(
                <SelectItem key={"treatmenttypes_pselect" + list.length} id={treatmentType.getSid()} data={{
                    type: treatmentType,
                }}>{treatmentType.getTitle().getLanguages()[0].getValue()} <ImagePreview imagePreviewIds={treatmentType.getImagePreviewIds()} description={treatmentType.getDescription()} ></ImagePreview></SelectItem>,
            ); // TODO: Multilanguage support!!
        }
        return list; // TODO: sort?
    }

    public treatmentColorsToSelectItems() {
        const list = [];
        for (const treatmentColor of this.getArticleTreatment().colors) {
            list.push(
                <DeleteSelectItem key={"treatmentcolors_pselect" + list.length}
                    onDeleteClick={() => {
                        this.removeArticleTreatmentColor(treatmentColor);
                    }}
                >{treatmentColor}</DeleteSelectItem>,
            ); // TODO: Multilanguage support!!
        }
        return list; // TODO: sort?
    }

    private async doValidityChecks(nextProps: IArticleCreationState) {
        /**
         * Validity checks
         */
        const enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.TREATMENT); // Validity check empty allowed
        let treatmentTypeValidity = { // TODO: move validity for when treatment === null to TreatmentInput validity checks
            valid: true,
            invalidMessage: "",
        };
        if(nextProps.article.treatment !== null)
            treatmentTypeValidity = await new ValidityErrorCatcher(() => TreatmentInput.typeValidityCheck(nextProps.article.treatment.type))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldige waarde.")
                .catchError();
        this.setState({
            validity: {
                treatmentTypeValidity,
            }
        })
    }

    componentDidMount() {
        this.doValidityChecks(this.props.article);
    }

    componentWillReceiveProps(nextProps) {
        this.doValidityChecks(nextProps.article);
    }

    constructor(a) {
        super(a);
        this.state = { validity: {} }
    }

    public render() {
        // TODO: type of input (for example unitCount should be integer)
        let treatmentTypeSelectItems = null;
        let treatmentColors = null;
        if (this.props.article.article.treatment !== null) {
            treatmentTypeSelectItems = this.treatmentTypesToSelectItems();
            treatmentColors = this.treatmentColorsToSelectItems();
        }

        // TODO: use list for colors instead of Select

        const applyTreatment = <span key={0}>
            <SubTitle>Behandeling voorzien?</SubTitle>
            <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                value={this.props.article.article.treatment !== null}
                onChange={async (selected) => {
                    if (selected && this.props.article.article.treatment === null)
                        await this.props.dispatch(setArticleTreatment(new TreatmentInput()));
                }}>Ja</PortalRadioButton>
            <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                value={this.props.article.article.treatment === null}
                onChange={async (selected) => {
                    if (selected) {
                        await this.props.dispatch(disableTreatment())
                    }
                }}>Nee</PortalRadioButton>
            <hr />
        </span>

        return (
            <StepTemplate pageType={PageType.TREATMENT} title={t`behandeling.`} next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/6"}>
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-6">
                            {applyTreatment}
                            {(() => {
                                if (this.props.article.article.treatment !== null) {
                                    return <span>
                                        <SubTitle>{t`Type behandeling`}</SubTitle>
                                        <ValidityIndicator
                                            {...this.state.validity.treatmentTypeValidity}
                                        >
                                            <Select placeholder={t`Type behandeling`} key={"_treatmenttype_select"}
                                                css={{ compose: PortalSelectStyle }}
                                                onSelectionChange={(data) => {
                                                    this.updateArticleTreatment(this.getArticleTreatmentInput().setType(data.type))
                                                }} selectedId={inn(this.getArticleTreatment().type, (a) => a.getSid())}>
                                                {treatmentTypeSelectItems}
                                            </Select>
                                        </ValidityIndicator>
                                    </span>
                                }
                            })()}
                        </div>
                        <div className="col-md-6">
                            {(() => {
                                if (this.props.article.article.treatment !== null) {
                                    return <span>
                                        <SubTitle>{t`Kleuren`}</SubTitle>
                                        <Select placeholder={t`Supplementen lak`} key={"_colorsupplementsselect"} searchable={false}
                                            css={{ compose: PortalSelectStyle }}
                                            onSelectionChange={() => { }} selectedIds={[]} selectedItems={[]} maxSelected={0}>
                                            {treatmentColors}
                                        </Select>
                                        <hr />
                                        <Input placeholder={t`Kleur`}
                                            onChange={(color) => this.setBasicInformation({ temp_treatmentColor: color })}
                                            value={this.props.article.temp_treatmentColor} />
                                        <RightFloatingMenuItem onClick={() => {
                                            this.addArticleTreatmentColor(this.props.article.temp_treatmentColor);
                                            this.setBasicInformation({ temp_treatmentColor: "" });
                                        }} title={t`Kleur toevoegen`} css={{ compose: rightFloatingMenuItemStyle }} />
                                    </span>
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </StepTemplate>
        );
    }
}
