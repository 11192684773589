import ObjectID from "bson-objectid";
import {isValidBoolean, isValidId, isValidNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface IHandrailType extends DataController {
    getPrice(): number;
    getL1Factor(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getDefaultHandrailHandleId(): ObjectID | null;
    getSupportedSpindleIds(): ObjectID[];
    isShowSpindle(): boolean;
    isExcludedFromPriceCalculations(): boolean;
    getImagePreviewIds(): string[];
    setPrice(newPrice: number): void | Promise<void>;
    setL1Factor(newFactor: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setShowSpindle(showSpindle: boolean): void | Promise<void>;
    setExcludedFromPriceCalculations(excludedFromPriceCalculations: boolean): void | Promise<void>;
    setDefaultHandrailHandleId(id: ObjectID | null): void | Promise<void>;
    setSupportedSpindleIds(ids: ObjectID[]): void | Promise<void>;
    setImagePreviewIds(ids: string[]): void | Promise<void>;
}

// TODO: almost entirely code duplication with StaircaseShape
export default class HandrailType extends DataController implements IHandrailType {

    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty()
    private l1Factor: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private showSpindle: boolean;
    @DataController.dataProperty()
    private excludedFromPriceCalculations: boolean;
    @DataController.dataProperty()
    private defaultHandrailHandleId: ObjectID | null;
    @DataController.dataProperty()
    private supportedSpindleIds: ObjectID[];
    @DataController.dataProperty()
    private imagePreviewIds: string[];

    public constructor(
        price: number,
        l1Factor: number,
        title: MultiLanguageString,
        description: MultiLanguageString,
        showSpindle: boolean,
        excludedFromPriceCalculations: boolean,
        defaultHandleId: ObjectID | null,
        supportedSpindleIds: ObjectID[],
        imagePreviewIds: string[] = [],
    ){
        super();
        this.setPrice(price);
        this.setL1Factor(l1Factor);
        this.setTitle(title);
        this.setDescription(description);
        this.setShowSpindle(showSpindle);
        this.setExcludedFromPriceCalculations(excludedFromPriceCalculations);
        this.setDefaultHandrailHandleId(defaultHandleId);
        this.setSupportedSpindleIds(supportedSpindleIds);
        this.setImagePreviewIds(imagePreviewIds);
    }

    public getImagePreviewIds(): string[] {
        return this.imagePreviewIds;
    }

    public setImagePreviewIds(ids: string[]): void {
        this.imagePreviewIds = ids;
    }

    public static isHandrailIncluded(handrailType: IHandrailType | undefined | null): boolean{
        if(handrailType === null || handrailType === undefined){
            return false;
        }
        return !handrailType.isExcludedFromPriceCalculations();
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getPrice(): number {
        return this.price;
    }

    public getL1Factor(): number {
        return this.l1Factor;
    }
    
    public getDefaultHandrailHandleId(): ObjectID {
        return this.defaultHandrailHandleId;
    }

    public getSupportedSpindleIds(): ObjectID[] {
        return this.supportedSpindleIds;
    }

    public isShowSpindle(): boolean {
        return this.showSpindle;
    }

    public isExcludedFromPriceCalculations(): boolean {
        return this.excludedFromPriceCalculations;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setPrice(newPrice: number): void {
        if(!isValidNumber(newPrice)) {
            throw VALUE_EXCEPTION;
        }
        this.price = Number(newPrice);
    }

    public setL1Factor(newFactor: number): void {
        if(!isValidNumber(newFactor)) {
            throw VALUE_EXCEPTION;
        }
        this.l1Factor = Number(newFactor);
    }

    public setDefaultHandrailHandleId(handrailHandleId: ObjectID | null): void {
        if(!isValidId(handrailHandleId) && handrailHandleId != null) {
            throw VALUE_EXCEPTION;
        }
        this.defaultHandrailHandleId = handrailHandleId;
    }

    public setSupportedSpindleIds(spindleIds: ObjectID[]): void {
        for (const id of spindleIds) {
            if(!isValidId(id) && id != null) {
                throw VALUE_EXCEPTION;
            }
        }
        this.supportedSpindleIds = spindleIds;
    }

    public setShowSpindle(showSpindle: boolean): void{
        if(!isValidBoolean(showSpindle))
            throw VALUE_EXCEPTION;
        this.showSpindle = showSpindle;
    }

    public setExcludedFromPriceCalculations(excludedFromPriceCalculations: boolean): void{
        if(!isValidBoolean(excludedFromPriceCalculations))
            throw VALUE_EXCEPTION;
        this.excludedFromPriceCalculations = excludedFromPriceCalculations;
    }

    clone(): HandrailType{
        return new HandrailType(
            this.price,
            this.l1Factor,
            this.title.clone(),
            this.description.clone(),
            this.showSpindle,
            this.excludedFromPriceCalculations,
            this.defaultHandrailHandleId,
            [...this.supportedSpindleIds],
            [...this.imagePreviewIds],
        )
    }

}
