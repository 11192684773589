import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";

export interface IHandrailHandle extends DataController {
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getImagePreviewIds(): string[];
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setImagePreviewIds(newImagePreviewIds: string[]): void | Promise<void>;
}

export default class HandrailHandle extends DataController implements IHandrailHandle {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private imagePreviewIds: string[];

    public constructor(title: MultiLanguageString, description: MultiLanguageString, imagePreviewIds: string[] = []){
        super();
        this.setTitle(title);
        this.setDescription(description);
        this.setImagePreviewIds(imagePreviewIds);
    }
    
    public getImagePreviewIds(): string[] {
        return this.imagePreviewIds;
    }

    public setImagePreviewIds(newImagePreviewIds: string[]): void {
        this.imagePreviewIds = newImagePreviewIds;
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public clone(): HandrailHandle{
        return new HandrailHandle(
            this.getTitle().clone(),
            this.getDescription().clone(),
            [...this.getImagePreviewIds()],
        );
    }

}
