import React from "react";
import { doApiCallWithSingleFile } from "webc-reactcore/src/js/apicommunication";
import { DeleteIcon } from "./Icon";

const Component = ({
    imagePreviewIds,
    onChange,
}:{
    imagePreviewIds: string[],
    onChange: (imagePreviewIds: string[]) => void,
}) => {

    return <div>
        <input type="file" id="logo-upload" name="file" onChange={async (e) => {
            const file: File = e.currentTarget.files[0];
            if (file) {
                const fileId = await new Promise<string>((resolve) => {
                    doApiCallWithSingleFile("hotec/publicupload", { file: file }, (result: any) => resolve(result.fileId));
                });
                onChange([...imagePreviewIds, fileId]);
            }
        }} />
        <div className="grid grid-flow-col justify-start gap-2 mt-2">
            {imagePreviewIds.map((x) => <div key={x} className="relative">
                <img
                    src={`/api/hotec/publicdownload/${x}`}
                    className="w-80 rounded-sm"
                />
                <button
                    className="absolute top-2 right-2 mr-0 rounded-sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange(imagePreviewIds.filter((y) => y !== x));
                    }}
                    title="Verwijder afbeelding"><DeleteIcon/></button>
            </div>)}
        </div>
    </div>

}

export default Component;