import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { isValidId, toNumber } from "orbiter-core/src/basic";
import ObjectID from "bson-objectid";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";

export interface IHandrailPoleType extends DataController {
    getPrice(): number | Promise<number>;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getDefaultHandrailPoleFinishId(): ObjectID | null | Promise<ObjectID> | Promise<null>;
    getImagePreviewIds(): string[];
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setDefaultHandrailPoleFinishId(id: ObjectID | null): void | Promise<void>;
    setImagePreviewIds(newImagePreviewIds: string[]): void | Promise<void>;
}

export default class HandrailPoleType extends DataController implements IHandrailPoleType {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty()
    private defaultHandrailPoleFinishId: ObjectID | null;
    @DataController.dataProperty()
    private imagePreviewIds: string[];

    public constructor(
        title: MultiLanguageString, 
        description: MultiLanguageString,
        price: number,
        defaultHandrailPoleFinishId: ObjectID | null,
        imagePreviewIds: string[] = [],
    ){
        super();
        this.setTitle(title);
        this.setDescription(description);
        this.setPrice(price);
        this.setDefaultHandrailPoleFinishId(defaultHandrailPoleFinishId);
        this.setImagePreviewIds(imagePreviewIds);
    }

    public getImagePreviewIds(): string[] {
        return this.imagePreviewIds;
    }

    public setImagePreviewIds(newImagePreviewIds: string[]): void {
        this.imagePreviewIds = newImagePreviewIds;
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }
    
    public getPrice(): number {
        return this.price;
    }

    public getDefaultHandrailPoleFinishId(): ObjectID {
        return this.defaultHandrailPoleFinishId;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }
    
    public setPrice(newPrice: number): void {
        // if(!isValidNumber(newPrice)) {
        //     throw VALUE_EXCEPTION;
        // }
        this.price = toNumber(newPrice);
    }

    public setDefaultHandrailPoleFinishId(handrailPoleFinishId: ObjectID | null): void {
        if(!isValidId(handrailPoleFinishId) && handrailPoleFinishId != null) {
            throw VALUE_EXCEPTION;
        }
        this.defaultHandrailPoleFinishId = handrailPoleFinishId;
    }

    public clone(): HandrailPoleType{
        return new HandrailPoleType(
            this.getTitle().clone(),
            this.getDescription().clone(),
            this.getPrice(),
            this.getDefaultHandrailPoleFinishId(),
            [...this.getImagePreviewIds()],
        )    
    }

}
