import { DispatchProp } from "react-redux";
import ArticleInput from "../../../../shared/datastructures/articleinput/ArticleInput";
import IArticleInput, { IShapeInput, IHandrailInput, ILandingStageInput, ITreatmentInput, ICommentInput, PageType, IExtraHandrailInput, IWallHandrailInput, ITransportationInput } from "../../../../shared/datastructures/IArticleInput";
import ShapeInput from "../../../../shared/datastructures/articleinput/ShapeInput";
import HandrailInput from "../../../../shared/datastructures/articleinput/HandrailInput";
import LandingStageInput from "../../../../shared/datastructures/articleinput/LandingStageInput";
import TreatmentInput from "../../../../shared/datastructures/articleinput/TreatmentInput";
import { IHandrailType } from "../../../../shared/datastructures/staircasedata/HandrailType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { ISpindleThreadType } from "../../../../shared/datastructures/staircasedata/SpindleThreadType";
import TransportationInput from "../../../../shared/datastructures/articleinput/TransportationInput";
import CommentInput from "../../../../shared/datastructures/articleinput/CommentInput";
import ExtraHandrailInput from "../../../../shared/datastructures/articleinput/ExtraHandrailInput";
import WallHandrailInput from "../../../../shared/datastructures/articleinput/WallHandrailInput";
import { IHandrailHandle } from "../../../../shared/datastructures/staircasedata/HandrailHandle";

// TODO: Split in different sub-objects
// TODO: interfaces for other components

// TODO: merge with Article object?!! Will make it much easier/safer to save and load articles from and to the state

export type IArticleCreationStateDispatch = IArticleCreationState & DispatchProp;

export interface IArticleCreationState {

    article: IArticleInput,

    temp_treatmentColor: string,
    temp_price: number,
    temp_price_staircase: number,
    temp_price_handrail: number,
    temp_price_treatment: number,
    temp_price_landingstage: number,
    temp_price_placement: number,
    temp_show_equal_placement_options: boolean,
    _tempValidityCheckEmptyAllowed: PageType[],

}

// TODO: use null instead of undefined when not set?
const defaultState: IArticleCreationState = {
    
    article: null,

    temp_treatmentColor: "",
    temp_price: 0,
    temp_price_staircase: 0,
    temp_price_handrail: 0,
    temp_price_treatment: 0,
    temp_price_landingstage: 0,
    temp_price_placement: 0,
    temp_show_equal_placement_options: true,
    _tempValidityCheckEmptyAllowed: [], // Indicates whether validity check should accept empty values or not. Empty values are allowed by default to prevent a bunch of validity errors showing up when starting to fill out the form

};

export default function reducer(state= defaultState, action){
    // MAKE SURE THE STATE IS NOT MUTATED, ALWAYS RETURN BRAND NEW VALUES
    switch(action.type){
        case "SET_ARTICLE_CREATION_BASIC_INFORMATION" : {
            return {...state, ...action.payload};
        }
        case "REMOVE_ARTICLE_CREATION_INFORMATION" : {
            return {...defaultState};
        }
        case "SET_ARTICLE": {
            return {...state, article:action.payload}
        }
        case "SET_ARTICLE_SHAPE": {
            const shape: IShapeInput = action.payload;
            const article = ArticleInput.from(state.article).setShape(ShapeInput.from(shape));
            return {...state, article}
        }
        case "SET_ARTICLE_TRANSPORTATION": {
            const transportation: ITransportationInput = action.payload;
            const article = ArticleInput.from(state.article).setTransportation(TransportationInput.from(transportation));
            return {...state, article}
        }
        case "SET_ARTICLE_COMMENTS": {
            const comments: ICommentInput[] = action.payload;
            const article = ArticleInput.from(state.article).setComments(comments.map(x=>CommentInput.from(x)));
            return {...state, article}
        }
        case "SET_ARTICLE_HANDRAIL": {
            const handrail: IHandrailInput = action.payload;
            const article = ArticleInput.from(state.article).setHandrail(HandrailInput.from(handrail));
            return {...state, article}
        }
        case "SET_ARTICLE_EXTRA_HANDRAIL": {
            const handrail: IExtraHandrailInput = action.payload;
            const article = ArticleInput.from(state.article).setExtraHandrail(ExtraHandrailInput.from(handrail));
            return {...state, article}
        }
        case "SET_ARTICLE_WALL_HANDRAIL": {
            const handrail: IWallHandrailInput = action.payload;
            const article = ArticleInput.from(state.article).setWallHandrail(WallHandrailInput.from(handrail));
            return {...state, article}
        }
        case "SET_ARTICLE_LANDING_STAGE": {
            const landingStage: ILandingStageInput = action.payload;
            const article = ArticleInput.from(state.article).setLandingStage(LandingStageInput.from(landingStage));
            return {...state, article}
        }
        case "SET_ARTICLE_LANDING_STAGE_HANDRAIL": {
            const landingStageHandrail: IHandrailInput = action.payload;
            let article;
            let showSpindle;
            if(landingStageHandrail === null){
                article = ArticleInput.from(state.article).setLandingStage(LandingStageInput.from(state.article.landingStage).setHandrail(null));
                showSpindle = false;
            }else{
                const landingStage: LandingStageInput = LandingStageInput.from(state.article.landingStage).setHandrail(HandrailInput.from(landingStageHandrail));
                article = ArticleInput.from(state.article).setLandingStage(landingStage);
                showSpindle = landingStageHandrail.type === null ? false : landingStageHandrail.type.isShowSpindle();
            }
            return {...state, article}
        }
        case "SET_ARTICLE_TREATMENT": {
            const treatment: ITreatmentInput = action.payload;
            const article = ArticleInput.from(state.article).setTreatment(TreatmentInput.from(treatment));
            return {...state, article};
        }
        case "ADD_ARTICLE_TREATMENT_COLOR":{
            const color: string = action.payload;
            const treatment: TreatmentInput = TreatmentInput.from(state.article.treatment).addColor(color);
            const article = ArticleInput.from(state.article).setTreatment(treatment);
            return {...state, article};
        }
        case "REMOVE_ARTICLE_TREATMENT_COLOR":{
            const color: string = action.payload;
            const original = TreatmentInput.from(state.article.treatment);
            const treatment: TreatmentInput = original.setColors(original.colors.filter(x => x !== color));
            const article = ArticleInput.from(state.article).setTreatment(treatment);
            return {...state, article};
        }
        case "SET_LANDING_STAGE_HANDRAIL_TYPE":{
            const newType: IHandrailType & IDatabaseController = action.payload;

            let newLandingStageHandrail;
            if(state.article.landingStage.handrail === null)
                newLandingStageHandrail = new HandrailInput();
            else
                newLandingStageHandrail = HandrailInput.from(state.article.landingStage.handrail);
                
            newLandingStageHandrail = newLandingStageHandrail.setType(newType);
            newLandingStageHandrail = newLandingStageHandrail.setSpindleThreadType(null);
            newLandingStageHandrail = newLandingStageHandrail.setSpindleThreadDescription(null);
            const article = ArticleInput.from(state.article).setLandingStage(LandingStageInput.from(state.article.landingStage).setHandrail(newLandingStageHandrail));
            return {...state, article};
        }
        case "SET_LANDING_STAGE_HANDRAIL_TYPE_AND_SPINDLE_THREAD_TYPE_AND_HANDRAIL_HANDLE":{
            const newHandrailType: IHandrailType & IDatabaseController = action.payload.handrailType;
            const newSpindleThreadType: ISpindleThreadType & IDatabaseController = action.payload.spindleThreadType;
            const newSpindleThreadDescription: string = action.payload.spindleThreadDescription;
            const newHandrailHandle: IHandrailHandle & IDatabaseController = action.payload.handrailHandle;

            let newLandingStageHandrail: HandrailInput;
            if(state.article.landingStage.handrail === null)
                newLandingStageHandrail = new HandrailInput();
            else
                newLandingStageHandrail = HandrailInput.from(state.article.landingStage.handrail);
                
            newLandingStageHandrail = newLandingStageHandrail.setType(newHandrailType);
            newLandingStageHandrail = newLandingStageHandrail.setSpindleThreadType(newSpindleThreadType);
            newLandingStageHandrail = newLandingStageHandrail.setSpindleThreadDescription(newSpindleThreadDescription);
            newLandingStageHandrail = newLandingStageHandrail.setHandle(newHandrailHandle);
            
            const article = ArticleInput.from(state.article).setLandingStage(LandingStageInput.from(state.article.landingStage).setHandrail(newLandingStageHandrail));
            return {...state, article};
        }
        case "DISABLE_LANDING_STAGE_HANDRAIL":{
            let lsi: LandingStageInput = LandingStageInput.from(state.article.landingStage);
            lsi = lsi.setHandrail(null);
            lsi = lsi.setHandrailLength(0);
            lsi = lsi.setHandrailPoleCount(0);
            lsi = lsi.setHandrailPoleTypes([]);
            
            const article = ArticleInput.from(state.article).setLandingStage(lsi);
            return {...state, article};
        }
        case "DISABLE_CONCRETE_ENCLOSURE":{
            let lsi: LandingStageInput = LandingStageInput.from(state.article.landingStage);
            lsi = lsi.setConcreteEnclosureWoodType(null);
            lsi = lsi.setConcreteEnclosureLength(0);
            lsi = lsi.setConcreteEnclosureWidth(0);
            
            const article = ArticleInput.from(state.article).setLandingStage(lsi);
            return {...state, article};
        }
        case "DISABLE_HANDRAIL":{
            let article: ArticleInput = ArticleInput.from(state.article);
            article = article.setHandrail(null);

            return {...state, article};
        }
        case "DISABLE_EXTRA_HANDRAIL":{
            let article: ArticleInput = ArticleInput.from(state.article);
            article = article.setExtraHandrail(null);

            return {...state, article};
        }
        case "ENABLE_EXTRA_HANDRAIL":{
            let article: ArticleInput = ArticleInput.from(state.article);
            const handrail = new ExtraHandrailInput();
            article = article.setExtraHandrail(handrail);

            return {...state, article};
        }
        case "DISABLE_WALL_HANDRAIL":{
            let article: ArticleInput = ArticleInput.from(state.article);
            article = article.setWallHandrail(null);

            return {...state, article};
        }
        case "ENABLE_WALL_HANDRAIL":{
            let article: ArticleInput = ArticleInput.from(state.article);
            const handrail = new WallHandrailInput();
            article = article.setWallHandrail(handrail);

            return {...state, article};
        }
        case "DISABLE_TREATMENT":{
            let article: ArticleInput = ArticleInput.from(state.article);
            article = article.setTreatment(null);

            return {...state, article};
        }
        case "SET_STAIRCASE_PLACEMENT_OPTIONS_EQUAL":{
            const allEqual = action.payload;
            let newTransportation = TransportationInput.from(state.article.transportation);
            if(allEqual){
                const newTransportationStaircasePlacementOptions = [];
                for(let i = 0; i<state.article.unitCount; i++){
                    newTransportationStaircasePlacementOptions.push(state.article.transportation.staircasePlacementOptions[0]);
                }
                newTransportation = newTransportation.setStaircasePlacementOptions(newTransportationStaircasePlacementOptions);
            }else{
                newTransportation = newTransportation.setStaircasePlacementOptions(state.article.transportation.staircasePlacementOptions.slice(0, state.article.unitCount));
            }
            const article = ArticleInput.from(state.article).setTransportation(newTransportation);
            return {...state, article, temp_show_equal_placement_options: allEqual};
        }
    }
    return state;
}
