import * as PortalSelectStyle from "../../css/PortalSelect.css";
import * as PortalDropdownStyle from "../../css/PortalDropdown.css";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import React from "react";
import { IQuantifiedSupplement } from "../../../../shared/datastructures/IArticleInput";
import { SupplementAPI } from "../apicontroller/staircasedata/SupplementAPI";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { t } from "ttag";
import SelectItem from "../components/AmountSelectItem";
import ImagePreview from "./ImagePreview";

const SupplementPicker = (props: { 
    placeholder: string,
    availableSupplements: SupplementAPI[],
    selectedSupplements: IQuantifiedSupplement[],
    onUpdateSupplements?: (supplements: IQuantifiedSupplement[]) => void
 }) => {

    const availableSupplementSelectItems = props.availableSupplements.map(x => ({
        id: x.getId(),
        title: dt(x.getTitle()),
        supplement: x,
        preview: <span>{dt(x.getTitle())} <ImagePreview imagePreviewIds={x.getImagePreviewIds()} description={x.getDescription()} /></span>,
    })).sort((a, b) => a.title < b.title ? -1 : 1);

    const selectedSupplementSelectItems = props.selectedSupplements.map(x => (
        <SelectItem key={"supplementstreadspselect" + x.supplement.getSid()}
            id={x.supplement.getId()}
            amount={x.amount}
            onDeleteClick={() => {
                props.onUpdateSupplements(props.selectedSupplements.filter(y => y.supplement.getSid() !== x.supplement.getSid()));
            }}
            onAmountChange={(newAmount) => {
                if(newAmount > x.supplement.getMaxPieces() && x.supplement.getMaxPieces() >= 0)
                    newAmount = x.supplement.getMaxPieces()
                props.onUpdateSupplements(props.selectedSupplements.map(y => y.supplement.getSid() !== x.supplement.getSid() ? y : {...y, amount: newAmount}));
            }}
            data={{
                supplement: x.supplement,
            }}>{dt((x.supplement as SupplementAPI).getTitle())} <ImagePreview imagePreviewIds={x.supplement.getImagePreviewIds()} description={x.supplement.getDescription()} ></ImagePreview></SelectItem>
    ));

    return (<>
        <Select placeholder={props.placeholder} key={props.placeholder}
            css={{ compose: PortalSelectStyle }}
            onSelectionChange={() => { }}
            maxSelected={0}
            selectedIds={[]}
            selectedItems={[]}>
            {selectedSupplementSelectItems}
        </Select>
        <PortalDropdown
            immediatelyClearSelection={true}
            placeholder={t`Supplement toevoegen`}
            css={{ compose: PortalDropdownStyle }}
            items={availableSupplementSelectItems}
            onSelectionChange={(data) => {
                if (data !== null) {
                    let newAmount = 1;
                    let exists = false;

                    for (const sup of props.selectedSupplements) {
                        if(sup.supplement.getSid() === data.supplement.getSid()){
                            newAmount += sup.amount;
                            exists = true;
                            break;
                        }
                    }
                    
                    if(newAmount > data.supplement.getMaxPieces() && data.supplement.getMaxPieces() >= 0)
                        newAmount = data.supplement.getMaxPieces()
                    
                    if(exists)
                        props.onUpdateSupplements(props.selectedSupplements.map(y => y.supplement.getSid() !== data.supplement.getSid() ? y : {...y, amount: newAmount}));
                    else
                        props.onUpdateSupplements([...props.selectedSupplements, {
                            supplement: data.supplement,
                            amount: newAmount,
                        }])
                }
            }}
        />
    </>)

}
export default SupplementPicker;