import * as React from "react";

import ApiController from "../../apicontroller/ApiController";
import SelectItem from "webc-reactcore/src/js/components/mainlayout/PortalSelectItem";
import PortalCollapsibleLanguageField from "webc-reactcore/src/js/components/mainlayout/PortalCollapsibleLanguageField";
import { t } from 'ttag';
import { inun } from "orbiter-core/src/basic";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import DataController from "orbiter-core/src/datastructures/DataController";
import SelectItemWithDelete from "../../components/DeleteSelectItem";
import ImagePreview from "../../components/ImagePreview";

export function toSelectItems<T extends ApiController<any>>(key: string, apiItems: T[], cloneFunction: (apiItem: T) => T, getDescriptor: (apiItem: T) => string): JSX.Element[] {
    const list = [];
    for (let apiItem of apiItems) {
        apiItem = cloneFunction(apiItem);
        list.push(
            <SelectItem key={key + list.length} id={apiItem.getSid()}
                data={{
                    item: apiItem,
                }}
            >{getDescriptor(apiItem)}</SelectItem>,
        );
    }
    return list;
}

export function toSelectItemsWithDelete<T extends ApiController<any>>(key: string, apiItems: T[], cloneFunction: (apiItem: T) => T, getDescriptor: (apiItem: T) => string, onDelete?: (apiItem: T) => void): JSX.Element[] {
    const list = [];
    for (let apiItem of apiItems) {
        apiItem = cloneFunction(apiItem);
        list.push(
            <SelectItemWithDelete key={key + list.length} id={apiItem.getSid()}
                onDeleteClick={() => onDelete(apiItem)}
                data={{
                    item: apiItem,
                }}
            >{getDescriptor(apiItem)}</SelectItemWithDelete>,
        );
    }
    return list;
}

export function toObjectSelectItems<T extends ApiController<any>>(apiItems: T[], cloneFunction: (apiItem: T) => T, getDescriptor: (apiItem: T) => string, getImagePreviewInfo?: (apiItem: T) => [string[], MultiLanguageString]): {title: string, id: string, item: any}[] {
    const list = [];
    for (let apiItem of apiItems) {
        apiItem = cloneFunction(apiItem);
        const preview = getImagePreviewInfo?.(apiItem);
        list.push(
            {
                title: getDescriptor(apiItem),
                preview: preview && <span>{getDescriptor(apiItem)} <ImagePreview imagePreviewIds={preview[0]} description={preview[1]} /></span>,
                id: apiItem.getSid(),
                item: apiItem,
            }
        );
    }
    return list.sort((a, b) => a.title < b.title ? -1 : 1);
}

export function createApiTitlePortalCollapsibleLanguageField<T extends ApiController<any> & {getTitle: () => MultiLanguageString}>(apiItem: T, cloneFunction: (apiItem: T) => T, setSelectedFunction: (T) => void): React.ReactNode {
    return createApiPortalCollapsibleLanguageField(t`Titel`, apiItem, cloneFunction, setSelectedFunction, (x: T) => x.getTitle(), (x: T, v: MultiLanguageString) => x.getData().setTitle(v));
}

export function createApiDescriptionPortalCollapsibleLanguageField<T extends ApiController<any> & {getDescription: () => MultiLanguageString}>(apiItem: T, cloneFunction: (apiItem: T) => T, setSelectedFunction: (T) => void): React.ReactNode {
    return createApiPortalCollapsibleLanguageField(t`Omschrijving`, apiItem, cloneFunction, setSelectedFunction, (x: T) => x.getDescription(), (x: T, v: MultiLanguageString) => x.getData().setDescription(v));
}

export function createApiPortalCollapsibleLanguageField<T extends ApiController<any>>(text: string, item: T, cloneFunction: (apiItem: T) => T, setSelectedFunction: (T) => void, get: (x: T) => MultiLanguageString, set: (x: T, v: MultiLanguageString) => void): React.ReactNode {
    return <PortalCollapsibleLanguageField legend={text}
    multiLanguageString={inun(item, (apiItem: T) => get(apiItem))}
    onChange={(mls: MultiLanguageString) => {
        const cl: T = cloneFunction(item);
        set(cl, mls);
        setSelectedFunction(cl);
    }}
/>;
}

export function createTitlePortalCollapsibleLanguageField<T extends DataController & {getTitle: () => MultiLanguageString, setTitle: (x: MultiLanguageString) => void}>(item: T, setSelectedFunction: (T) => void): React.ReactNode {
    return createPortalCollapsibleLanguageField(t`Titel`, item, setSelectedFunction, (x: T) => x.getTitle(), (x: T, v: MultiLanguageString) => x.setTitle(v));
}

export function createDescriptionPortalCollapsibleLanguageField<T extends DataController & {getDescription: () => MultiLanguageString, setDescription: (x: MultiLanguageString) => void}>(item: T, setSelectedFunction: (T) => void): React.ReactNode {
    return createPortalCollapsibleLanguageField(t`Omschrijving`, item, setSelectedFunction, (x: T) => x.getDescription(), (x: T, v: MultiLanguageString) => x.setDescription(v));
}

export function createPortalCollapsibleLanguageField<T extends DataController>(text: string, item: T, setSelectedFunction: (T) => void, get: (x: T) => MultiLanguageString, set: (x: T, v: MultiLanguageString) => void): React.ReactNode {
    return <PortalCollapsibleLanguageField legend={text}
        multiLanguageString={inun(item, (apiItem: T) => get(apiItem))}
        onChange={(mls: MultiLanguageString) => {
            const cl: T = item.clone() as T;
            set(cl, mls);
            setSelectedFunction(cl);
        }}
    />;
}