import * as React from "react";
import { connect, DispatchProp } from "react-redux";

import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import { setArticle, setStaircasePlacementOptionsEqual, setArticleShape } from "../../../actions/articleCreationActions";
import SubTitle from "../../../components/SubTitle";
import StepTemplate from "../StepTemplate";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { inn } from "orbiter-core/src/basic";
import * as PortalDropdownStyle from "../../../../css/PortalDropdown.css";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import ArticleInput from "../../../../../../shared/datastructures/articleinput/ArticleInput";
import IArticleInput, { IShapeInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import { INPUT_NOT_DEFINED, INVALID_TYPE } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import Warning from "../../../components/Overview/Warning";
import {t} from "ttag";
import InputIndicator from "../../../components/Overview/InputIndicator";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import ShapeInput from "../../../../../../shared/datastructures/articleinput/ShapeInput";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import ImagePreview from "../../../components/ImagePreview";

@(connect((store: any) => {
    return {article: {...store.articleCreation}, quote: {...store.quoteCreation}};
}) as any)
export default class Staircase extends React.Component<{article: IArticleCreationState, quote: IQuoteCreationState} & DispatchProp, any> {

    private async updateArticle(article: ArticleInput) {
        await this.props.dispatch(setArticle(article));
    }

    private getArticle(): IArticleInput{
        return this.props.article.article;
    }

    private getArticleInput():ArticleInput{
        return ArticleInput.from(this.props.article.article);
    }

    private getShapeInput(): ShapeInput{
        return ShapeInput.from(this.props.article.article.shape);
    }

    private getShape(): IShapeInput{
        return this.props.article.article.shape;
    }

    private updateArticleShape(shape: IShapeInput) {
        this.props.dispatch(setArticleShape(shape));
    }

    public staircaseTypesToSelectItems() {
        const list = [];
        for (const staircaseType of this.props.quote.availableStaircaseTypes) {
            list.push(
                {
                    title: staircaseType.getTitle().getLanguages()[0].getValue(),
                    id: staircaseType.getSid(),
                    type: staircaseType,
                    preview: <span>{dt(staircaseType.getTitle())} <ImagePreview imagePreviewIds={staircaseType.getImagePreviewIds()} description={staircaseType.getDescription()} /></span>,
                }
            ); // TODO: Multilanguage support!!
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    public woodTypesToSelectItems() {
        const list = [];
        for (const type of this.props.quote.availableWoodTypes) {
            list.push(
                {
                    preview: <span>{dt(type.getTitle())} <ImagePreview imagePreviewIds={type.getImagePreviewIds()} description={type.getDescription()} /></span>,
                    title: dt(type.getTitle()),
                    id: type.getSid(),
                    woodType: type,
                }
            ); // TODO: Multilanguage support!!
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    public riserWoodTypesToSelectItems() {
        const list = [];
        for (const type of this.props.quote.availableWoodTypes) {
            if(type.isAvailableForRisers())
                list.push(
                    {
                        title: dt(type.getTitle()),
                        preview: <span>{dt(type.getTitle())} <ImagePreview imagePreviewIds={type.getImagePreviewIds()} description={type.getDescription()} /></span>,
                        id: type.getSid(),
                        woodType: type,
                    }
                ); // TODO: Multilanguage support!!
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private async checkValidity(nextProps: IArticleCreationState){
        /**
         * Validity checks
         */
        const enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.STAIRCASE); // Validity check empty allowed
        const unitCountValidity = await new ValidityErrorCatcher(() => ArticleInput.unitCountValidityCheck(nextProps.article.unitCount))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief geheel getal in.`)
            .catchError();

        function fpHelper(a){
            return a.addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief getal in.`)
                .catchError()
        }
        const widthValidity = await fpHelper(new ValidityErrorCatcher(() => ArticleInput.widthValidityCheck(nextProps.article.width)));
        const floorHeightValidity = await fpHelper(new ValidityErrorCatcher(() => ArticleInput.floorHeightValidityCheck(nextProps.article.floorHeight)));
        const treadCountValidity = await fpHelper(new ValidityErrorCatcher(() => ArticleInput.treadCountValidityCheck(nextProps.article.treadCount)));
        
        const staircaseTypeValidity = await new ValidityErrorCatcher(() => ArticleInput.staircaseTypeValidityCheck(nextProps.article.type))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig traptype.`)
            .catchError();

        const woodTypeValidity = await new ValidityErrorCatcher(() => ArticleInput.woodTypeValidityCheck(nextProps.article.woodType))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig materiaaltype.`)
            .catchError();

        const riserWoodTypeValidity = await new ValidityErrorCatcher(() => ArticleInput.riserWoodTypeValidityCheck(nextProps.article.riserWoodType))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig materiaaltype.`)
            .catchError();

        this.setState ({ validity: {
            unitCountValidity,
            widthValidity,
            floorHeightValidity,
            treadCountValidity,
            staircaseTypeValidity,
            woodTypeValidity,
            riserWoodTypeValidity,
        }})
    }

    constructor(a){
        super(a);
        this.state = {validity: {}, warningRiserWoodType: "", showTreadCalculationButton: false,}
    }

    componentDidMount(){
        this.checkValidity(this.props.article);
    }

    componentWillReceiveProps(nextProps){
        this.checkValidity(nextProps.article);
    }

    public render() {
        // TODO: type of input (for example unitCount should be integer)
        // TODO: why does PortalRadioButton always generate a setBasicInformation (SET_ARTICLE_CREATION_BASIC_INFORMATION) action on load?

        return (
            <StepTemplate pageType={PageType.STAIRCASE} title={t`trap.`} next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/2"}>
                {this.subRender()}
            </StepTemplate>
        );
    }
    
    public subRender(addedOptions?) {
        return (
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col-md-4">
                        <SubTitle>{t`Algemene informatie`}</SubTitle>
                        <MappedInput placeholder={t`Referentie`}
                            onBlur={async (reference: string) => {
                                this.updateArticle(this.getArticleInput().setReference(reference));
                            }}
                            value={this.getArticle().reference} 
                        />
                        <ValidityIndicator
                            {...this.state.validity.unitCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal dezelfde trappen`}
                                onBlur={async (unitCount: string) => {
                                    unitCount = unitCount.replace(",", ".");
                                    this.updateArticle(this.getArticleInput().setUnitCount(parseInt(unitCount)));
                                    await this.props.dispatch(setStaircasePlacementOptionsEqual(this.props.article.temp_show_equal_placement_options));
                                }}
                                value={this.getArticle().unitCount} />
                        </ValidityIndicator>
                        <ValidityIndicator
                            {...this.state.validity.widthValidity}
                        >
                            <MappedInput placeholder={t`Breedte trap (cm)`}
                                onBlur={(width: string) => {
                                    width = width.replace(",", ".");
                                    this.updateArticle(this.getArticleInput().setWidth(parseFloat(width)));
                                }}
                                value={this.getArticle().width} />
                        </ValidityIndicator>

                        <SubTitle>{t`Tredes`}</SubTitle>
                        {(() => {
                            const vdi = <ValidityIndicator
                                {...this.state.validity.floorHeightValidity}
                            >
                                <MappedInput placeholder={t`Verdiepingshoogte (cm)`}
                                    onBlur={(floorHeight: string) => {
                                        floorHeight = floorHeight.replace(",", ".");
                                        const newFloorHeight = parseFloat(floorHeight);
                                        const oldFloorHeight = this.getArticle().floorHeight;
                                        this.updateArticle(this.getArticleInput().setFloorHeight(newFloorHeight));
                                        if(newFloorHeight !== oldFloorHeight){
                                            this.setState({showTreadCalculationButton: true});
                                        }
                                    }}
                                    value={this.getArticle().floorHeight} />
                            </ValidityIndicator>;
                            
                            if(this.state.showTreadCalculationButton){
                                return <InputIndicator
                                    message={t`Aantal tredes automatisch berekenen?`}
                                    onClick={(apply)=>{
                                        this.setState({showTreadCalculationButton: false});
                                        if(apply){
                                            const treadCount = Math.ceil(this.getArticle().floorHeight / 18); // Todo round up, correct divider?
                                            this.updateArticle(this.getArticleInput().setTreadCount(treadCount));
                                        }
                                    }}
                                    buttons={[{key: true, title: t`Ja`}, {key: false, title: t`Nee`}]}
                                >
                                    {vdi}
                                </InputIndicator>;
                            }else{
                                return vdi;
                            }

                        })()}

                        <ValidityIndicator
                            {...this.state.validity.treadCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal tredes`}
                                onBlur={(treadCount: string) => {
                                    this.updateArticle(this.getArticleInput().setTreadCount(parseInt(treadCount)));
                                }}
                                value={this.getArticle().treadCount} />
                        </ValidityIndicator>
                    </div>
                    <div className="col-md-4">
                        <SubTitle>{t`Traptype`}</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.staircaseTypeValidity}
                        >
                            <PortalDropdown placeholder={t`Traptype`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.staircaseTypesToSelectItems()}
                                onSelectionChange={async (data) => {
                                    await this.updateArticle(this.getArticleInput().setType(data === null ? null : data.type));
                                }}
                                selectedId={inn(this.getArticle().type, (a) => a.getSid())}
                            />
                        </ValidityIndicator>

                        {addedOptions}

                    </div>
                    <div className="col-md-4">
                        <SubTitle>{t`Materiaal`}</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.woodTypeValidity}
                        >
                            <PortalDropdown placeholder={t`Materiaaltype`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.woodTypesToSelectItems()}
                                onSelectionChange={async (data) => {
                                    await this.updateArticle(this.getArticleInput().setWoodType(data === null ? null : data.woodType));
                                    if(data !== null && data.woodType.isAvailableForRisers() && this.getArticle().riserWoodType === null){
                                        await this.updateArticle(this.getArticleInput().setRiserWoodType(data === null ? null : data.woodType));
                                        this.setState({warningRiserWoodType: <Warning>Opgelet: dit materiaaltype werd ook toegepast als materiaaltype voor de tegentredes.</Warning>});
                                    }else{
                                        this.setState({warningRiserWoodType: ""});
                                    } 
                                }}
                                selectedId={inn(this.getArticle().woodType, (a) => a.getSid())}
                            /> 
                        </ValidityIndicator>
                        {this.state.warningRiserWoodType}
                        <ValidityIndicator
                            {...this.state.validity.riserWoodTypeValidity}
                        >
                            <PortalDropdown placeholder={"Materiaaltype tegentredes"}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.riserWoodTypesToSelectItems()}
                                onSelectionChange={async (data) => {
                                    await this.updateArticle(this.getArticleInput().setRiserWoodType(data === null ? null : data.woodType));
                                }}
                                selectedId={inn(this.getArticle().riserWoodType, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                    </div>
                </div>
            </div>
        );
    }

}
