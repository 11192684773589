import * as React from "react";
import { connect, DispatchProp } from "react-redux";
import { setArticleLandingStage, setArticleLandingStageHandrail, disableLandingStageHandrail, disableConcreteEnclosure } from "../../../actions/articleCreationActions";
import StepTemplate from "../StepTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import SubTitle from "../../../components/SubTitle";
import * as PortalDropdownStyle from "../../../../css/PortalDropdown.css";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { inn, inun, isDefined } from "orbiter-core/src/basic";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import HandrailTypeAPI from "../../../apicontroller/staircasedata/HandrailTypeAPI";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import { ILandingStageInput, IHandrailInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import HandrailInput from "../../../../../../shared/datastructures/articleinput/HandrailInput";
import LandingStageInput from "../../../../../../shared/datastructures/articleinput/LandingStageInput";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import { INPUT_NOT_DEFINED, INVALID_TYPE } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import { t } from "ttag";
import Warning from "../../../components/Overview/Warning";
import ObjectID from "bson-objectid";
import HandrailHandleAPI from "../../../apicontroller/staircasedata/HandrailHandleAPI";
import HandrailType from "../../../../../../shared/datastructures/staircasedata/HandrailType";
import { spindleThreadTypesToSelectItems } from "./helpers/handrailHelpers";
import HandrailPoleTypeAPI from "../../../apicontroller/staircasedata/HandrailPoleTypeAPI";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import { convertToNearestHalveMeter } from "../../../../../../shared/helpers";
import SupplementPicker from "../../../components/SupplementPicker";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";
import ImagePreview from "../../../components/ImagePreview";


@(connect((store: any) => {
    return { article: { ...store.articleCreation }, quote: { ...store.quoteCreation } };
}) as any)
export default class LandingStage extends React.Component<{ article: IArticleCreationState, quote: IQuoteCreationState } & DispatchProp, any> {

    private async updateLandingStage(landingStage: ILandingStageInput) {
        await this.props.dispatch(setArticleLandingStage(landingStage));
    }

    private async updateLandingStageHandrail(landingStageHandrail: IHandrailInput) {
        await this.props.dispatch(setArticleLandingStageHandrail(landingStageHandrail));
    }

    private async updateSupplements(supplements) {
        const newHandrail = HandrailInput.from(this.props.article.article.landingStage.handrail).setSupplements(supplements);
        await this.updateLandingStageHandrail(newHandrail);
    }

    private getHandrailInput(): HandrailInput {
        return HandrailInput.from(this.props.article.article.landingStage.handrail);
    }

    private getLandingStageInput(): LandingStageInput {
        return LandingStageInput.from(this.getLandingStage());
    }

    private getLandingStage(): ILandingStageInput {
        return this.props.article.article.landingStage;
    }

    private getHandrailHandleById(id: ObjectID | null): HandrailHandleAPI | null {
        const handle = this.props.quote.availableHandrailHandles.find(a => a.getId().equals(id));
        return handle ? handle : null;
    }

    private landingStageTypesToSelectItems() {
        const list = [];
        let landingStageType: HandrailTypeAPI;
        for (landingStageType of this.state.allowedHandrailTypes) {
            list.push(
                {
                    id: landingStageType.getSid(),
                    preview: <span>{dt(landingStageType.getTitle())} <ImagePreview imagePreviewIds={landingStageType.getImagePreviewIds()} description={landingStageType.getDescription()} /></span>,
                    title: dt(landingStageType.getTitle()),
                    type: landingStageType,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    private handrailPoleTypesToSelectItems() { // TODO: code duplication with Handrail.tsx
        const list = [];
        for (const poleType of this.props.quote.availableHandrailPoleTypes) {
            list.push(
                {
                    id: poleType.getSid(),
                    title: dt(poleType.getTitle()),
                    handrailPoleType: poleType,
                    preview: <span>{dt(poleType.getTitle())} <ImagePreview imagePreviewIds={poleType.getImagePreviewIds()} description={poleType.getDescription()} /></span>,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    private handrailPoleFinishesToSelectItems() { // TODO: code duplication with Handrail.tsx
        const list = [];
        for (const poleFinish of this.props.quote.availableHandrailPoleFinishes) {
            list.push(
                {
                    id: poleFinish.getSid(),
                    title: dt(poleFinish.getTitle()),
                    preview: <span>{dt(poleFinish.getTitle())} <ImagePreview imagePreviewIds={poleFinish.getImagePreviewIds()} description={poleFinish.getDescription()} ></ImagePreview></span>,
                    handrailPoleFinish: poleFinish,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    private woodTypesToSelectItems() { // TODO: different wood types for 'afkasting'
        const list = [];
        for (const type of this.props.quote.availableWoodTypes) {
            list.push(
                {
                    id: type.getSid(),
                    preview: <span>{dt(type.getTitle())} <ImagePreview imagePreviewIds={type.getImagePreviewIds()} description={type.getDescription()} /></span>,
                    title: dt(type.getTitle()),
                    woodType: type,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }



    private handrailHandlesToSelectItems() { // TODO: duplicate Handrail.tsx
        const list = [];
        for (const handrailHandle of this.props.quote.availableHandrailHandles) {
            list.push(
                {
                    id: handrailHandle.getSid(),
                    preview: <span>{dt(handrailHandle.getTitle())} <ImagePreview imagePreviewIds={handrailHandle.getImagePreviewIds()} description={handrailHandle.getDescription()} /></span>,
                    title: handrailHandle.getTitle().getLanguages()[0].getValue(),
                    handle: handrailHandle,
                }
            ); // TODO: Multilanguage support!!
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private async checkValidity(nextProps: IArticleCreationState) {
        /**
         * Validity checks
         */
        const enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.LANDING_STAGE); // Validity check empty allowed

        function fpHelper(a) {
            return a.addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldige waarde.")
                .addCatcher(enableEmptyCheck, INVALID_TYPE, "Geef een positief getal in.")
                .catchError()
        }
        const handrailLengthValidity = await fpHelper(new ValidityErrorCatcher(() => LandingStageInput.handrailLengthValidityCheck(nextProps.article.landingStage.handrailLength)));
        const concreteEnclosureLengthValidity = await fpHelper(new ValidityErrorCatcher(() => LandingStageInput.concreteEnclosureLengthValidityCheck(nextProps.article.landingStage.concreteEnclosureLength, nextProps.article.landingStage)));
        const concreteEnclosureWidthValidity = await fpHelper(new ValidityErrorCatcher(() => LandingStageInput.concreteEnclosureWidthValidityCheck(nextProps.article.landingStage.concreteEnclosureWidth, nextProps.article.landingStage)));

        const concreteEnclosureWoodTypeValidity = await new ValidityErrorCatcher(() => LandingStageInput.concreteEnclosureWoodTypeValidityCheck(nextProps.article.landingStage.concreteEnclosureWoodType, nextProps.article.landingStage))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldige waarde.")
            .catchError();

        const typeValidity = await new ValidityErrorCatcher(async () => await HandrailInput.typeValidityCheck(nextProps.article.landingStage.handrail.type, nextProps.article?.shape?.staircaseShape, true))
            .addCatcher(enableEmptyCheck && nextProps.article.landingStage.handrail !== null, INPUT_NOT_DEFINED, "Kies een geldige waarde.")
            .catchError();
        const SpindleThreadTypeValidity = await new ValidityErrorCatcher(() => HandrailInput.spindleThreadTypeValidityCheck(nextProps.article.landingStage.handrail.spindleThreadType, nextProps.article.landingStage.handrail.type))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldige waarde.")
            .catchError();
        const handrailHandleValidity = await new ValidityErrorCatcher(() => HandrailInput.handleValidityCheck(nextProps.article.landingStage.handrail.handle))
            .catchError();

        const handrailPoleCountValidity = await fpHelper(new ValidityErrorCatcher(() => LandingStageInput.handrailPoleCountValidityCheck(nextProps.article.landingStage.handrailPoleCount)));
        const handrailPoleTypesValidity = await new ValidityErrorCatcher(() => LandingStageInput.handrailPoleTypesValidityCheck(nextProps.article.landingStage.handrailPoleTypes, nextProps.article.landingStage.handrailPoleCount, nextProps.article.landingStage.handrail?.type))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldig type voor elke paal.")
            .catchError();
        const handrailPoleFinishValidity = await new ValidityErrorCatcher(() => LandingStageInput.handrailPoleFinishValidityCheck(nextProps.article.landingStage.handrailPoleFinishes, nextProps.article.landingStage.handrailPoleCount, nextProps.article.landingStage.handrail?.type))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldig type voor elke paal.")
            .catchError();



        this.setState({
            validity: {
                handrailValidity: {
                    typeValidity,
                    SpindleThreadTypeValidity,
                },
                handrailHandleValidity,
                handrailLengthValidity,
                concreteEnclosureLengthValidity,
                concreteEnclosureWidthValidity,
                concreteEnclosureWoodTypeValidity,
                handrailPoleCountValidity,
                handrailPoleTypesValidity,
                handrailPoleFinishValidity,
            }
        })
    }

    constructor(a) {
        super(a);
        this.state = { validity: { handrailValidity: {} }, warningDefaultHandrailHandle: "", showConcreteEnclosure: null, allowedHandrailTypes: [] };
    }

    componentDidMount() {
        this.runUpdate(this.props);
        this.setState({ showConcreteEnclosure: this.props.article.article.landingStage.concreteEnclosureWoodType !== null });
    }

    componentWillReceiveProps(nextProps) {
        this.runUpdate(nextProps);
        const concreteEnclosureSetExternally = nextProps.article.article.landingStage.concreteEnclosureWoodType !== null && this.state.showConcreteEnclosure === false;
        this.setState({ showConcreteEnclosure: concreteEnclosureSetExternally ? true : (this.state.showConcreteEnclosure === null ? this.props.article.article.landingStage.concreteEnclosureWoodType !== null : this.state.showConcreteEnclosure) });
    }

    runUpdate(props) {
        const article: IArticleCreationState = props.article;
        this.checkValidity(article);
        (async () => {

            const allowedHandrailTypes: HandrailTypeAPI[] = [];
            for (let i = 0; i < props.quote.availableHandrailTypes.length; i++) {
                const type: HandrailTypeAPI = props.quote.availableHandrailTypes[i];
                allowedHandrailTypes.push(type);
            }

            this.setState({
                allowedHandrailTypes,
            })

        })()
    }

    private isHandrailSelected(): boolean {
        return HandrailType.isHandrailIncluded(this.getLandingStage().handrail?.type);
    }

    private subRenderLandingStageHandrailPoles() {
        const result = [];

        if (!this.isHandrailSelected())
            return result;

        result.push(<SubTitle key="hptitle">Palen en eindstukken</SubTitle>);

        /**
         * Landing stage handrail pole count
         */
        result.push(<div key={"hp1"}>
            <ValidityIndicator
                {...this.state.validity.handrailPoleCountValidity}
            >
                <MappedInput placeholder="Aantal palen"
                    onBlur={(poleCount: string) => {
                        let convertedPoleCount = parseInt(poleCount);
                        this.updateLandingStage(this.getLandingStageInput().setHandrailPoleCount(convertedPoleCount).setHandrailPoleTypes([]).setHandrailPoleFinishes([]));
                    }}
                    value={this.getLandingStage().handrailPoleCount} />
            </ValidityIndicator>
        </div>);

        /**
         * Handrail pole types
         */
        const handrailPoleTypeDropdowns = [];
        const numberOfPoles: number = inn(this.props.article.article.landingStage, _ => _.handrailPoleCount, () => 0);
        for (let i = 0; i < numberOfPoles; i++) {
            handrailPoleTypeDropdowns.push(
                <PortalDropdown key={"handrailpoletype_" + i}
                    placeholder={"Paal " + (i + 1).toString()}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.handrailPoleTypesToSelectItems()}
                    onSelectionChange={(data) => {
                        let newLS = this.getLandingStageInput();
                        const newHandrailPoleTypes = [...newLS.handrailPoleTypes];
                        newHandrailPoleTypes[i] = data === null ? undefined : data.handrailPoleType;
                        newLS = newLS.setHandrailPoleTypes(newHandrailPoleTypes);

                        // Update pole finish
                        const pt: HandrailPoleTypeAPI = newHandrailPoleTypes[i] as HandrailPoleTypeAPI;
                        const hasFinish = isDefined(newLS.handrailPoleFinishes[i]);
                        if (!hasFinish && isDefined(pt) && pt.getDefaultHandrailPoleFinishId() != null) {
                            let newHandrailPoleFinishes = [...newLS.handrailPoleFinishes];
                            newHandrailPoleFinishes[i] = this.props.quote.availableHandrailPoleFinishes.find(y =>
                                y.getId().equals(pt.getDefaultHandrailPoleFinishId())
                            );
                            newLS = newLS.setHandrailPoleFinishes(newHandrailPoleFinishes);
                        }

                        this.updateLandingStage(newLS);
                    }}
                    selectedId={inun(this.getLandingStageInput().handrailPoleTypes[i], (a) => a.getSid(), () => null)}
                />
            );
        }
        /**
         * Handrail pole finishes
         */
        const handrailPoleFinishDropdowns = [];
        for (let i = 0; i < numberOfPoles; i++) {
            handrailPoleFinishDropdowns.push(
                <PortalDropdown key={"handrailpolefinish_" + i}
                    placeholder={"Bovenafwerking " + (i + 1).toString()}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.handrailPoleFinishesToSelectItems()}
                    onSelectionChange={(data) => {
                        let newLS = this.getLandingStageInput();
                        const newHandrailPoleFinishes = [...newLS.handrailPoleFinishes];
                        newHandrailPoleFinishes[i] = data === null ? undefined : data.handrailPoleFinish;
                        console.log("newHandrailPoleFinishes", newHandrailPoleFinishes, data)
                        newLS = newLS.setHandrailPoleFinishes(newHandrailPoleFinishes);
                        this.updateLandingStage(newLS);
                    }}
                    selectedId={inun(this.getLandingStageInput().handrailPoleFinishes[i], (a) => a.getSid(), () => null)}
                />
            );
        }
        result.push(<div key="handrailselectedpaaltypesenbovenafwerking" className="container-fluid" style={{ padding: 0 }}>
            <div className="row">
                <div className="col-md-6">
                    <ValidityIndicator
                        {...this.state.validity.handrailPoleTypesValidity}
                    >
                        {handrailPoleTypeDropdowns}
                    </ValidityIndicator>
                </div>
                <div className="col-md-6">
                    <ValidityIndicator
                        {...this.state.validity.handrailPoleFinishValidity}
                    >
                        {handrailPoleFinishDropdowns}
                    </ValidityIndicator>
                </div>
            </div>
        </div>);

        return result;
    }


    private subRenderLandingStageHandrail( /* Validity Check Empty Allowed */) {
        const result = [];

        result.push(
            <span key={0}>
                <SubTitle>Overloopleuning voorzien?</SubTitle>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={this.props.article.article.landingStage.handrail !== null}
                    onChange={async (selected) => {
                        if (selected && this.props.article.article.landingStage.handrail === null)
                            await this.props.dispatch(setArticleLandingStageHandrail(new HandrailInput()))
                    }}>Ja</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={this.props.article.article.landingStage.handrail === null}
                    onChange={async (selected) => {
                        if (selected) {
                            await this.props.dispatch(disableLandingStageHandrail())
                        }
                    }}>Nee</PortalRadioButton>
                <hr />
            </span>
        );

        if (this.props.article.article.landingStage.handrail == null)
            return result;

        /**
         * Handrail type
         */
        result.push(<div key={1}>
            <SubTitle>Leuningtype</SubTitle>
            <ValidityIndicator
                {...this.state.validity.handrailValidity.typeValidity}
            >
                <PortalDropdown placeholder={"Leuningtype"}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.landingStageTypesToSelectItems()}
                    onSelectionChange={async (data) => {
                        let newHandrail = this.getHandrailInput();
                        newHandrail = newHandrail
                            .setType(data === null ? null : data.type)
                            .setSpindleThreadType(null)
                            .setSpindleThreadDescription(null);

                        if (newHandrail.type?.isExcludedFromPriceCalculations()) {
                            await this.props.dispatch(disableLandingStageHandrail())
                            return;
                        }

                        let warningDefaultHandrailHandle: any = "";
                        const newHandrailType: HandrailTypeAPI = newHandrail.type as HandrailTypeAPI;
                        if (newHandrail.handle === null && newHandrailType !== null && newHandrailType.getDefaultHandrailHandleId() !== null) {
                            warningDefaultHandrailHandle = <Warning>Opgelet: de bijbehorende standaard handgreep werd toegepast.</Warning>;
                            const handle: HandrailHandleAPI = this.getHandrailHandleById(newHandrailType.getDefaultHandrailHandleId());
                            newHandrail = newHandrail
                                .setHandle(handle);
                        }

                        await this.props.dispatch(setArticleLandingStageHandrail(newHandrail));

                        this.setState({
                            warningDefaultHandrailHandle,
                        })
                    }}
                    selectedId={inn(this.getLandingStage().handrail.type, (a) => a.getSid())}
                />
            </ValidityIndicator>
            {this.state.warningDefaultHandrailHandle}
        </div>);

        if (!this.isHandrailSelected())
            return result;

        /**
         * Spindles
         */
        if ((this.props.article.article.landingStage?.handrail?.type as HandrailTypeAPI)?.isShowSpindle())
            result.push(<div key={2}>
                {/* <SubTitle>Modelspijlen</SubTitle> */}
                <ValidityIndicator
                    {...this.state.validity.handrailValidity.SpindleThreadTypeValidity}
                >
                    <PortalDropdown placeholder={"Type modelspijlen"}
                        css={{ compose: PortalDropdownStyle }}
                        items={spindleThreadTypesToSelectItems(this.props.quote.availableSpindleThreadTypes, (this.getLandingStage().handrail.type as HandrailTypeAPI).getSupportedSpindleIds())}
                        onSelectionChange={async (data) => {
                            await this.updateLandingStageHandrail(this.getHandrailInput().setSpindleThreadType(data === null ? null : data.type))
                        }}
                        selectedId={inn(this.getLandingStage().handrail.spindleThreadType, (a) => a.getSid())}
                    />
                </ValidityIndicator>
                <Input placeholder={t`Omschrijving modelspijlen`} onChange={(description) => this.updateLandingStageHandrail(this.getHandrailInput().setSpindleThreadDescription(description))} onBlur={(description: string) => this.updateLandingStageHandrail(this.getHandrailInput().setSpindleThreadDescription(description))} value={this.getLandingStage()?.handrail?.spindleThreadDescription ?? ""}/>
            </div>);

        /**
         * Landing stage handrail length
         */
        result.push(<div key={"2handle"}>
            <SubTitle>Handgreep</SubTitle>
            <ValidityIndicator
                {...this.state.validity.handrailHandleValidity}
            >
                <PortalDropdown placeholder={t`Handgreep`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.handrailHandlesToSelectItems()}
                    onSelectionChange={(data) => {
                        this.updateLandingStageHandrail(this.getHandrailInput().setHandle(data === null ? null : data.handle));
                    }}
                    selectedId={inn(this.getLandingStage().handrail.handle, (a) => a.getSid())}
                />
            </ValidityIndicator>
        </div>);

        /**
         * Landing stage handrail length
         */
        result.push(<div key={3}>
            <SubTitle>Lopende meter overloopleuning</SubTitle>
            <ValidityIndicator
                {...this.state.validity.handrailLengthValidity}
            >
                <MappedInput placeholder="Lopende meter overloopleuning"
                    onBlur={(handrailLength: string) => {
                        handrailLength = handrailLength.replace(",", ".");
                        let convertedHandrailLength = convertToNearestHalveMeter(parseFloat(handrailLength));
                        this.updateLandingStage(this.getLandingStageInput().setHandrailLength(convertedHandrailLength));
                    }}
                    value={this.getLandingStage().handrailLength} />
            </ValidityIndicator>
        </div>);

        /**
         * Handrail supplements
         */
        result.push(<div key={4}>
            <SubTitle>Supplementen leuning</SubTitle>
            <SupplementPicker
                placeholder={t`Supplementen leuning`}
                availableSupplements={this.props.quote.availableSupplementsLandingStageHandrail}
                selectedSupplements={this.props.article.article.landingStage.handrail.supplements}
                onUpdateSupplements={(sup) => {
                    this.updateSupplements(sup);
                }}
            />
        </div>);


        return result;
    }

    private subRenderLandingStageConcreteEnclosure( /* Validity Check Empty Allowed */) {
        const result = [];

        const showConcreteEnclosure: boolean = this.props.article.article.landingStage.concreteEnclosureWoodType !== null || this.state.showConcreteEnclosure;

        result.push(<div key={"afkastingvoorzien"}>
            <SubTitle>Afkasting voorzien?</SubTitle>
            <ValidityIndicator
                valid={showConcreteEnclosure ? true : (this.state.validity.concreteEnclosureWoodTypeValidity ? this.state.validity.concreteEnclosureWoodTypeValidity.valid : false)}
                invalidMessage={this.state.validity.concreteEnclosureWoodTypeValidity ? " " + this.state.validity.concreteEnclosureWoodTypeValidity.invalidMessage : ""}
            >
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={showConcreteEnclosure}
                    onChange={async (selected) => {
                        if (selected && this.props.article.article.landingStage.concreteEnclosureWoodType === null)
                            this.setState({ showConcreteEnclosure: true });
                    }}>Ja</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={!showConcreteEnclosure}
                    onChange={(selected) => {
                        if (selected) {
                            this.setState({ showConcreteEnclosure: false }, () => {
                                this.props.dispatch(disableConcreteEnclosure());
                            });
                        }
                    }}>Nee</PortalRadioButton>
            </ValidityIndicator>
            <hr />
        </div>)

        /**
         * Concrete enclosure material type
         */
        if (showConcreteEnclosure) {
            result.push(<div key={1}>
                <SubTitle>Materiaal afkasting</SubTitle>
                <ValidityIndicator
                    {...this.state.validity.concreteEnclosureWoodTypeValidity}
                >
                    <PortalDropdown placeholder={"Materiaaltype afkasting"}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.woodTypesToSelectItems()}
                        onSelectionChange={async (data) => {
                            await this.updateLandingStage(this.getLandingStageInput().setConcreteEnclosureWoodType(data === null ? null : data.woodType));
                        }}
                        selectedId={inn(this.getLandingStage().concreteEnclosureWoodType, (a) => a.getSid())}
                    />
                </ValidityIndicator>
            </div>);

            /**
             * Concrete enclosure length
             */
            result.push(<div key={2}>
                <SubTitle>Lopende meter afkasting</SubTitle>
                <ValidityIndicator
                    {...this.state.validity.concreteEnclosureLengthValidity}
                >
                    <MappedInput placeholder="Lopende meter afkasting"
                        onBlur={(concreteEnclosureLength: string) => {
                            concreteEnclosureLength = concreteEnclosureLength.replace(",", ".");
                            let convertedConcreteEnclosureLength = convertToNearestHalveMeter(parseFloat(concreteEnclosureLength));
                            this.updateLandingStage(this.getLandingStageInput().setConcreteEnclosureLength(convertedConcreteEnclosureLength));
                        }}
                        value={this.getLandingStage().concreteEnclosureLength} />

                </ValidityIndicator>
            </div>);

            /**
             * Concrete enclosure width
             */
            result.push(<div key={3}>
                <SubTitle>Breedte afkasting</SubTitle>
                <ValidityIndicator
                    {...this.state.validity.concreteEnclosureWidthValidity}
                >
                    <MappedInput placeholder="Breedte afkasting (cm)"
                        onBlur={(concreteEnclosureWidth: string) => {
                            concreteEnclosureWidth = concreteEnclosureWidth.replace(",", ".");
                            this.updateLandingStage(this.getLandingStageInput().setConcreteEnclosureWidth(parseFloat(concreteEnclosureWidth)));
                        }}
                        value={this.getLandingStage().concreteEnclosureWidth} />
                </ValidityIndicator>
            </div>);
        }

        return result;
    }

    private subRenderWood() {

        return (
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col-md-4">
                        {this.subRenderLandingStageHandrail()}
                    </div>
                    <div className="col-md-4">
                        {this.subRenderLandingStageHandrailPoles()}
                    </div>
                    <div className="col-md-4">
                        {this.subRenderLandingStageConcreteEnclosure()}
                    </div>
                </div>
            </div>
        );
    }

    private subRenderConcrete() {
        return this.subRenderWood(); // TODO: replace with concrete-specific input
    }

    public render() {
        // TODO: type of input (for example unitCount should be integer)

        let r = this.props.article.article.isWood ? this.subRenderWood() : this.subRenderConcrete();

        return (
            <StepTemplate pageType={PageType.LANDING_STAGE} title="overloop." next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/5"}>
                {r}
            </StepTemplate>
        );
    }
}
