import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import {
    default as StaircaseType,
    IStaircaseType,
} from "../../../../../shared/datastructures/staircasedata/StaircaseType";
import ApiController from "../ApiController";

// TODO: too much code duplication
export default class StaircaseTypeAPI extends ApiController<StaircaseType> implements IStaircaseType {

    public static getBaseEndpoint(): string{
        return "hotec/staircasetype";
    }

    public static getApiKey(): string{
        return "staircaseType";
    }

    public static getPluralApiKey(): string{
        return "types";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const st = new StaircaseType(
            dictionary.treadPrice,
            dictionary.handrailPrice,
            dictionary.installationFactor,
            title,
            desc,
            dictionary.numberOfPoles,
            dictionary.imagePreviewIds,
        );
        this.setData(st);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getNumberOfPoles(): number {
        return this.getData().getNumberOfPoles();
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setNumberOfPoles(numberOfPoles: number): Promise<void> {
        this.getData().setNumberOfPoles(numberOfPoles);
        await this.updateProperty("numberOfPoles", numberOfPoles);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public getHandrailPrice(): number {
        return this.getData().getHandrailPrice();
    }

    public getTreadPrice(): number {
        return this.getData().getTreadPrice();
    }

    public getInstallationFactor(): number {
        return this.getData().getInstallationFactor();
    }

    public async setHandrailPrice(newPrice: number): Promise<void> {
        this.getData().setHandrailPrice(newPrice);
        await this.updateProperty("handrailPrice", newPrice);
    }

    public async setTreadPrice(newPrice: number): Promise<void> {
        this.getData().setTreadPrice(newPrice);
        await this.updateProperty("treadPrice", newPrice);
    }

    public async setInstallationFactor(newInstallationFactor: number): Promise<void> {
        this.getData().setInstallationFactor(newInstallationFactor);
        await this.updateProperty("installationFactor", newInstallationFactor);
    }

    public getImagePreviewIds(): string[] {
        return this.getData().getImagePreviewIds();
    }
    
    public async setImagePreviewIds(newImagePreviewIds: string[]): Promise<void> {
        this.getData().setImagePreviewIds(newImagePreviewIds);
        await this.updateProperty("imagePreviewIds", newImagePreviewIds);
    }

}
