import * as React from "react";

interface IconFactoryProps{
    height?: string
}
const prefix: string = "/cdn/portal/assets/icons/";
const suffix: string = ".svg";
const bld = (name: string) => prefix + name + suffix;

export const TestIcon = (props: IconFactoryProps) => <Icon src={bld("tree-log")} {...props}/>
export const DuplicateIcon = (props: IconFactoryProps) => <Icon src={bld("layers-front")} {...props}/>
export const DeleteIcon = (props: IconFactoryProps) => <Icon src={bld("trash")} {...props}/>
export const VariantIcon = (props: IconFactoryProps) => <Icon src={bld("variant")} {...props}/>
export const CheckIcon = (props: IconFactoryProps) => <Icon src={bld("check")} {...props}/>
export const NoCheckIcon = (props: IconFactoryProps) => <Icon src={bld("no-check")} {...props}/>
export const EyeIcon = (props: IconFactoryProps) => <Icon src={bld("eye")} {...props}/>
export const NoEyeIcon = (props: IconFactoryProps) => <Icon src={bld("no-eye")} {...props}/>
export const DiscountIcon = (props: IconFactoryProps) => <Icon src={bld("discount")} {...props}/>
export const InfoIcon = (props: IconFactoryProps) => <Icon src={bld("info")} {...props}/>

interface IconProps{
    src: string;
    height?: string;
}

class Icon extends React.Component<IconProps> {
    public render() {
        return (
            <img src={this.props.src} height={this.props.height ?? "15px"}/>
        );
    }
}