import * as React from "react";
import { connect, DispatchProp } from "react-redux";

import { setArticleHandrail, setBasicInformation, disableLandingStageHandrail, setLandingStageHandrailTypeAndSpindleThreadTypeAndHandrailHandle, disableHandrail, disableExtraHandrail, enableExtraHandrail, setArticleExtraHandrail, enableWallHandrail, disableWallHandrail, setArticleWallHandrail } from "../../../actions/articleCreationActions";
import SubTitle from "../../../components/SubTitle";
import StepTemplate from "../StepTemplate";
import * as PortalDropdownStyle from "../../../../css/PortalDropdown.css";
import { inn, inun, isDefined } from "orbiter-core/src/basic";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import HandrailInput from "../../../../../../shared/datastructures/articleinput/HandrailInput";
import { IExtraHandrailInput, IHandrailInput, IWallHandrailInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import HandrailType from "../../../../../../shared/datastructures/staircasedata/HandrailType";
import InputIndicator from "../../../components/Overview/InputIndicator";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import { INPUT_NOT_DEFINED, INVALID_TYPE, PROHIBITED_SELECTION } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import HandrailTypeAPI from "../../../apicontroller/staircasedata/HandrailTypeAPI";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { t } from "ttag";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import StaircaseTypeAPI from "../../../apicontroller/staircasedata/StaircaseTypeAPI";
import Warning from "../../../components/Overview/Warning";
import HandrailHandleAPI from "../../../apicontroller/staircasedata/HandrailHandleAPI";
import ObjectID from "bson-objectid";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { spindleThreadTypesToSelectItems } from "./helpers/handrailHelpers";
import HandrailPoleTypeAPI from "../../../apicontroller/staircasedata/HandrailPoleTypeAPI";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import ExtraHandrailInput from "../../../../../../shared/datastructures/articleinput/ExtraHandrailInput";
import WallHandrailInput from "../../../../../../shared/datastructures/articleinput/WallHandrailInput";
import ArticleInput from "../../../../../../shared/datastructures/articleinput/ArticleInput";
import StaircaseShapeAPI from "../../../apicontroller/staircasedata/StaircaseShapeAPI";
import SupplementPicker from "../../../components/SupplementPicker";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import ImagePreview from "../../../components/ImagePreview";

@(connect((store: any) => {
    return { article: { ...store.articleCreation }, quote: { ...store.quoteCreation } };
}) as any)
export default class Handrail extends React.Component<{ article: IArticleCreationState, quote: IQuoteCreationState } & DispatchProp, any> {

    constructor(a) {
        super(a);
        this.state = {
            applyTypeToLandingStageVisible: false,
            validity: {
                enableEmptyCheck: { valid: true },
                typeValidity: { valid: true },
                spindleThreadTypeValidity: { valid: true },
                handrailPoleTypesValidity: { valid: true },
                handrailPoleFinishValidity: { valid: true },

                extraHandrail: {
                    typeValidity: { valid: true },
                    spindleThreadTypeValidity: { valid: true },
                    handrailPoleTypesValidity: { valid: true },
                    handrailPoleFinishValidity: { valid: true },
                    poleCountValidity: {valid: true},
                    treadCountValidity: {valid: true},
                },

                wallHandrail: {
                    typeValidity: {valid: true},
                    hookValidity: {valid: true},
                    lengthValidity: {valid: true},
                    hookCountValidity: {valid: true},
                    bendCountValidity: {valid: true},
                },
            },
            warningDefaultHandrailHandle: "",
            warningDefaultExtraHandrailHandle: "",
            allowedHandrailTypes: [],
            allowedExtraHandrailTypes: [],
        }
    }

    private updateHandrail(handrail: HandrailInput) {
        this.props.dispatch(setArticleHandrail(handrail));
    }

    private updateExtraHandrail(handrail: ExtraHandrailInput) {
        this.props.dispatch(setArticleExtraHandrail(handrail));
    }

    private updateWallHandrail(handrail: WallHandrailInput) {
        this.props.dispatch(setArticleWallHandrail(handrail));
    }

    private updateBasicInformation(information) {
        this.props.dispatch(setBasicInformation(information));
    }

    private getHandrailInput(): HandrailInput {
        return HandrailInput.from(this.props.article.article.handrail);
    }

    private getHandrail(): IHandrailInput {
        return this.props.article.article.handrail;
    }

    private getWallHandrailInput(): WallHandrailInput {
        return WallHandrailInput.from(this.props.article.article.wallHandrail);
    }

    private getWallHandrail(): IWallHandrailInput {
        return this.props.article.article.wallHandrail;
    }

    private getExtraHandrailInput(): ExtraHandrailInput | null {
        return ExtraHandrailInput.from(this.props.article.article.extraHandrail);
    }

    private getExtraHandrail(): IExtraHandrailInput | null {
        return this.props.article.article.extraHandrail;
    }

    private updateSupplements(supplements) {
        const newHandrail = HandrailInput.from(this.props.article.article.handrail).setSupplements(supplements);
        this.updateHandrail(newHandrail);
    }

    private handrailTypesToSelectItems() {
        const list = [];
        for (const handrailType of this.state.allowedHandrailTypes) {
            list.push(
                {
                    id: handrailType.getSid(),
                    preview: <span>{dt(handrailType.getTitle())} <ImagePreview imagePreviewIds={handrailType.getImagePreviewIds()} description={handrailType.getDescription()} /></span>,
                    title: dt(handrailType.getTitle()),
                    type: handrailType,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private wallHandrailTypesToSelectItems() {
        const list = [];
        for (const handrailType of this.props.quote.availableWallHandrails) {
            list.push(
                {
                    id: handrailType.getSid(),
                    title: dt(handrailType.getTitle()),
                    type: handrailType,
                    preview: <span>{dt(handrailType.getTitle())} <ImagePreview imagePreviewIds={handrailType.getImagePreviewIds()} description={handrailType.getDescription()} /></span>,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private hooksToSelectItems() {
        const list = [];
        for (const hook of this.props.quote.availableWallHooks) {
            list.push(
                {
                    id: hook.getSid(),
                    title: dt(hook.getTitle()),
                    type: hook,
                    preview: <span>{dt(hook.getTitle())} <ImagePreview imagePreviewIds={hook.getImagePreviewIds()} description={hook.getDescription()} /></span>,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }


    private handrailHandlesToSelectItems() {
        const list = [];
        for (const handrailHandle of this.props.quote.availableHandrailHandles) {
            list.push(
                {
                    id: handrailHandle.getSid(),
                    title: dt(handrailHandle.getTitle()),
                    preview: <span>{dt(handrailHandle.getTitle())} <ImagePreview imagePreviewIds={handrailHandle.getImagePreviewIds()} description={handrailHandle.getDescription()} ></ImagePreview></span>,
                    handle: handrailHandle,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private getHandrailHandleById(id: ObjectID | null): HandrailHandleAPI | null {
        const handle = this.props.quote.availableHandrailHandles.find(a => a.getId().equals(id));
        return handle ? handle : null;
    }

    private handrailPoleTypesToSelectItems() {
        const list = [];
        for (const poleType of this.props.quote.availableHandrailPoleTypes) {
            list.push(
                {
                    id: poleType.getSid(),
                    title: dt(poleType.getTitle()),
                    handrailPoleType: poleType,
                    preview: <span>{dt(poleType.getTitle())} <ImagePreview imagePreviewIds={poleType.getImagePreviewIds()} description={poleType.getDescription()} /></span>,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private handrailPoleFinishesToSelectItems() {
        const list = [];
        for (const poleFinish of this.props.quote.availableHandrailPoleFinishes) {
            list.push(
                {
                    id: poleFinish.getSid(),
                    title: dt(poleFinish.getTitle()),
                    preview: <span>{dt(poleFinish.getTitle())} <ImagePreview imagePreviewIds={poleFinish.getImagePreviewIds()} description={poleFinish.getDescription()} ></ImagePreview></span>,
                    handrailPoleFinish: poleFinish,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);;
    }

    private async doValidityChecks(nextProps: IArticleCreationState) {
        /**
         * Validity checks
         */

        let enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.HANDRAIL); // Validity check empty allowedue
        let typeValidity = { valid: true, invalidMessage: "" };
        let spindleThreadTypeValidity = { valid: true, invalidMessage: "" };
        let handrailHandleValidity = { valid: true, invalidMessage: "" };
        let handrailPoleTypesValidity = { valid: true, invalidMessage: "" };
        let handrailPoleFinishValidity = { valid: true, invalidMessage: "" };

        // Extra handrail (eh)
        let ehTypeValidity = { valid: true, invalidMessage: "" };
        let ehSpindleThreadTypeValidity = { valid: true, invalidMessage: "" };
        let ehHandrailHandleValidity = { valid: true, invalidMessage: "" };
        let ehHandrailPoleTypesValidity = { valid: true, invalidMessage: "" };
        let ehHandrailPoleFinishValidity = { valid: true, invalidMessage: "" };
        let ehTreadCountValidity = { valid: true, invalidMessage: "" };
        let ehPoleCountValidity = { valid: true, invalidMessage: "" };

        // Wall handrail (wh)
        let whTypeValidity = { valid: true, invalidMessage: "" };
        let whHookValidity = { valid: true, invalidMessage: "" };
        let whLengthValidity = { valid: true, invalidMessage: "" };
        let whHookCountValidity = { valid: true, invalidMessage: "" };
        let whBendCountValidity = { valid: true, invalidMessage: "" };
        

        if (nextProps.article.handrail !== null) {// TODO: move validity for when handrail === null to HandrailInput validity checks

            typeValidity = await new ValidityErrorCatcher(async () => await HandrailInput.typeValidityCheck(nextProps.article.handrail.type, nextProps.article?.shape?.staircaseShape))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
                .addCatcher(true, PROHIBITED_SELECTION, t`Dit leuningtype kan niet gebruikt worden in combinatie met de geselecteerde trapvorm.`)
                .catchError();
            
            spindleThreadTypeValidity = await new ValidityErrorCatcher(() => HandrailInput.spindleThreadTypeValidityCheck(nextProps.article.handrail.spindleThreadType, nextProps.article.handrail?.type))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
                .catchError();
            
            handrailHandleValidity = await new ValidityErrorCatcher(() => HandrailInput.handleValidityCheck(nextProps.article.handrail.handle))
                .catchError();
            
            handrailPoleTypesValidity = await new ValidityErrorCatcher(await (async () => HandrailInput.poleTypesValidityCheck(nextProps.article.handrail.handrailPoleTypes, await nextProps.article.type.getNumberOfPoles(), nextProps.article.handrail?.type)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig type voor elke paal.`)
                .catchError();
            
            handrailPoleFinishValidity = await new ValidityErrorCatcher(await (async () => HandrailInput.poleFinishValidityCheck(nextProps.article.handrail.handrailPoleFinishes, await nextProps.article.type.getNumberOfPoles(), nextProps.article.handrail?.type)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig type voor elke bovenafwerking.`)
                .catchError();

        }

        if(ArticleInput.extraHandrailEnabled(nextProps.article)){
            ehTypeValidity = await new ValidityErrorCatcher(async () => await ExtraHandrailInput.typeValidityCheck(nextProps.article.extraHandrail.type, nextProps.article?.shape?.staircaseShape))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
                .addCatcher(true, PROHIBITED_SELECTION, t`Dit leuningtype kan niet gebruikt worden in combinatie met de geselecteerde trapvorm.`)
                .catchError();
        
            ehSpindleThreadTypeValidity = await new ValidityErrorCatcher(() => ExtraHandrailInput.spindleThreadTypeValidityCheck(nextProps.article.extraHandrail.spindleThreadType, nextProps.article.extraHandrail?.type))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
                .catchError();
            
            ehHandrailHandleValidity = await new ValidityErrorCatcher(() => ExtraHandrailInput.handleValidityCheck(nextProps.article.extraHandrail.handle))
                .catchError();
            
            ehHandrailPoleTypesValidity = await new ValidityErrorCatcher(await (async () => ExtraHandrailInput.poleTypesValidityCheck(nextProps.article.extraHandrail.handrailPoleTypes, nextProps.article.extraHandrail.poleCount, nextProps.article.extraHandrail?.type)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig type voor elke paal.`)
                .catchError();
            
            ehHandrailPoleFinishValidity = await new ValidityErrorCatcher(await (async () => ExtraHandrailInput.poleFinishValidityCheck(nextProps.article.extraHandrail.handrailPoleFinishes, nextProps.article.extraHandrail.poleCount, nextProps.article.extraHandrail?.type)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldig type voor elke bovenafwerking.`)
                .catchError();

            ehTreadCountValidity = await new ValidityErrorCatcher(await (async () => ExtraHandrailInput.treadCountValidityCheck(nextProps.article.extraHandrail!.treadCount)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Geef een strikt positief getal in.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een strikt positief getal in.`)
                .catchError();

            ehPoleCountValidity = await new ValidityErrorCatcher(await (async () => ExtraHandrailInput.poleCountValidityCheck(nextProps.article.extraHandrail!.poleCount)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Geef een positief getal in.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief getal in.`)
                .catchError();

        }

        if(ArticleInput.wallHandrailEnabled(nextProps.article)){
            whTypeValidity = await new ValidityErrorCatcher(() => WallHandrailInput.typeValidityCheck(nextProps.article.wallHandrail.type))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .catchError();
            
            whHookValidity = await new ValidityErrorCatcher(() => WallHandrailInput.hookValidityCheck(nextProps.article.wallHandrail.hook))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .catchError();

            whLengthValidity = await new ValidityErrorCatcher(await (async () => WallHandrailInput.lengthValidityCheck(nextProps.article.wallHandrail.length)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Geef een strikt positief getal in.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een strikt positief getal in.`)
                .catchError();

            whHookCountValidity = await new ValidityErrorCatcher(await (async () => WallHandrailInput.hookCountValidityCheck(nextProps.article.wallHandrail.hookCount, nextProps.article.wallHandrail.hook)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Geef een positief getal in.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief getal in.`)
                .catchError();

            whBendCountValidity = await new ValidityErrorCatcher(await (async () => WallHandrailInput.bendCountValidityCheck(nextProps.article.wallHandrail.bendCount)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Geef een positief getal in.`)
                .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief getal in.`)
                .catchError();
        }


        const validity = {
            enableEmptyCheck,
            typeValidity,
            spindleThreadTypeValidity,
            handrailHandleValidity,
            handrailPoleTypesValidity,
            handrailPoleFinishValidity,
            extraHandrail: {
                typeValidity: ehTypeValidity,
                spindleThreadTypeValidity: ehSpindleThreadTypeValidity,
                handrailHandleValidity: ehHandrailHandleValidity,
                handrailPoleTypesValidity: ehHandrailPoleTypesValidity,
                handrailPoleFinishValidity: ehHandrailPoleFinishValidity,
                poleCountValidity: ehPoleCountValidity,
                treadCountValidity: ehTreadCountValidity,
            },
            wallHandrail: {
                typeValidity: whTypeValidity,
                hookValidity: whHookValidity,
                lengthValidity: whLengthValidity,
                hookCountValidity: whHookCountValidity,
                bendCountValidity: whBendCountValidity,
            }
        };

        this.setState({
            validity,
        })
    }

    componentDidMount() {
        this.runUpdate(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.runUpdate(nextProps);
    }

    runUpdate(props){
        const article: IArticleCreationState = props.article;
        this.doValidityChecks(article);
        (async () => {

            const shape: StaircaseShapeAPI = article.article?.shape?.staircaseShape as StaircaseShapeAPI;

            const allowedHandrailTypes: HandrailTypeAPI[] = [];
            for (let i = 0; i < props.quote.availableHandrailTypes.length; i++) {
                const type: HandrailTypeAPI = props.quote.availableHandrailTypes[i];
                if(!shape || type.getSid() === this.getHandrail()?.type?.getSid() || !shape.isProhibitedHandrailTypeId(type.getId())){
                    allowedHandrailTypes.push(type);
                }
            }

            this.setState({
                allowedHandrailTypes,
            })

        })()
    }

    public subRenderWood() {

        const defaultHandrailPart = [];

        const handrailSelected: boolean = HandrailType.isHandrailIncluded(this.getHandrail()?.type);
        const extraHandrailSelected: boolean = HandrailType.isHandrailIncluded(this.getExtraHandrail()?.type);

        const provideHandrail = (
            <span key={0}>
                <SubTitle>Leuning voorzien?</SubTitle>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={this.props.article.article.handrail !== null}
                    onChange={async (selected) => {
                        if (selected && this.props.article.article.handrail === null)
                            await this.props.dispatch(setArticleHandrail(new HandrailInput()))
                    }}>Ja</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={this.props.article.article.handrail === null}
                    onChange={async (selected) => {
                        if (selected) {
                            await this.props.dispatch(disableHandrail())
                        }
                    }}>Nee</PortalRadioButton>
            </span>
        );

        let dropdown = null;
        let spindleDropdown = [];
        let inputIndicator = null;

        if (this.props.article.article.handrail !== null) {
            /**
             * Handrail type
             */
            dropdown =
                <ValidityIndicator
                    {...this.state.validity.typeValidity}
                >
                    <PortalDropdown placeholder={t`Leuningtype`}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailTypesToSelectItems()}
                        onSelectionChange={async (data) => {
                            let newHandrail = this.getHandrailInput();
                            newHandrail = newHandrail.setType(data === null ? null : data.type);
                            newHandrail = newHandrail.setHandrailPoleTypes([]);
                            newHandrail = newHandrail.setHandrailPoleFinishes([]);

                            if (newHandrail.type?.isExcludedFromPriceCalculations()) {
                                await this.props.dispatch(disableHandrail())
                                return;
                            }
                            
                            newHandrail = newHandrail.setSpindleThreadType(null);
                            newHandrail = newHandrail.setSpindleThreadDescription(null);

                            let warningDefaultHandrailHandle: any = "";
                            const newHandrailType: HandrailTypeAPI = newHandrail.type as HandrailTypeAPI;
                            if (newHandrail.handle === null && newHandrailType !== null && newHandrailType.getDefaultHandrailHandleId() !== null) {
                                warningDefaultHandrailHandle = <Warning>Opgelet: de bijbehorende standaard handgreep werd toegepast.</Warning>;
                                const handle: HandrailHandleAPI = this.getHandrailHandleById(newHandrailType.getDefaultHandrailHandleId());
                                newHandrail = newHandrail.setHandle(handle);
                            }

                            this.updateHandrail(newHandrail);

                            this.setState({
                                applyTypeToLandingStageVisible: true,
                                warningDefaultHandrailHandle,
                            })
                        }}
                        selectedId={inn(this.getHandrail().type, (a) => a.getSid())}
                    />
                </ValidityIndicator>;

            /**
             * Spindle
             */
            if (handrailSelected && (this.props.article.article?.handrail?.type as HandrailTypeAPI)?.isShowSpindle()) {
                spindleDropdown.push(
                    <div key="spdd1">
                        {/* <SubTitle>Modelspijlen</SubTitle> */}
                        <ValidityIndicator
                            {...this.state.validity.spindleThreadTypeValidity}
                        >
                            <PortalDropdown placeholder={t`Type modelspijlen`}
                                css={{ compose: PortalDropdownStyle }}
                                items={spindleThreadTypesToSelectItems(this.props.quote.availableSpindleThreadTypes, (this.getHandrail().type as HandrailTypeAPI).getSupportedSpindleIds())}
                                onSelectionChange={(data) => {
                                    this.updateHandrail(this.getHandrailInput().setSpindleThreadType(data === null ? null : data.type));
                                    this.setState({
                                        applyTypeToLandingStageVisible: true,
                                    })
                                }}
                                selectedId={inn(this.getHandrail().spindleThreadType, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        <Input placeholder={t`Omschrijving modelspijlen`} onChange={(description) => this.updateHandrail(this.getHandrailInput().setSpindleThreadDescription(description))} onBlur={(description: string) => this.updateHandrail(this.getHandrailInput().setSpindleThreadDescription(description))} value={this.getHandrail().spindleThreadDescription ?? ""}/>
                    </div>
                );
            }
            const dropdownWithSpindleDropdown = <div>
                {dropdown}
                {spindleDropdown}
            </div>
            inputIndicator = this.state.applyTypeToLandingStageVisible ?
                <InputIndicator
                    message={t`Ook toepassen op overloopleuning?`}
                    onClick={(apply) => {
                        let warningDefaultHandrailHandle = this.state.warningDefaultHandrailHandle;
                        if (apply) {
                            const type: HandrailTypeAPI = this.props.article.article.handrail.type as HandrailTypeAPI;
                            this.props.dispatch(setLandingStageHandrailTypeAndSpindleThreadTypeAndHandrailHandle(type, this.props.article.article.handrail.spindleThreadType, this.props.article.article.handrail.spindleThreadDescription, type ? this.getHandrailHandleById(type.getDefaultHandrailHandleId()) : null));
                            warningDefaultHandrailHandle = <Warning>Opgelet: de bijbehorende standaard handgreep werd ook toegepast op de overloopleuning.</Warning>;
                        } else if (apply === null) {
                            this.props.dispatch(disableLandingStageHandrail());
                        }

                        this.setState({
                            applyTypeToLandingStageVisible: false,
                            warningDefaultHandrailHandle,
                        })
                    }}
                    buttons={[{ key: true, title: t`Ja` }, { key: false, title: t`Nee` }, { key: null, title: t`Geen overloopleuning voorzien` }]}
                >
                    {dropdownWithSpindleDropdown}
                </InputIndicator> : dropdownWithSpindleDropdown;

        }
        defaultHandrailPart.push(
            <div className="col-md-4" key={1}>
                
                {provideHandrail}

                {(() => {
                    if (this.props.article.article.handrail !== null) {
                        return [
                            <span key="hsleuningtype">
                                <hr />
                                <SubTitle>Leuningtype</SubTitle>
                                {inputIndicator}
                                {this.state.warningDefaultHandrailHandle}
                            </span>,
                            <SubTitle key="hshandgreep">Handgreep</SubTitle>,
                            <ValidityIndicator key="hshandgreepvalidityindicator"
                                {...this.state.validity.handrailHandleValidity}
                            >
                                <PortalDropdown placeholder={t`Handgreep`}
                                    css={{ compose: PortalDropdownStyle }}
                                    items={this.handrailHandlesToSelectItems()}
                                    onSelectionChange={(data) => {
                                        this.updateHandrail(this.getHandrailInput().setHandle(data === null ? null : data.handle));
                                    }}
                                    selectedId={inn(this.getHandrail().handle, (a) => a.getSid())}
                                />
                            </ValidityIndicator>
                        ]
                    }
                })()}

            </div>
        );
        if (this.props.article.article.handrail !== null) {
            /**
             * Handrail pole types
             */
            const handrailPoleTypeDropdowns = [];
            const numberOfPoles: number = inn(this.props.article.article.type as StaircaseTypeAPI, _ => _.getNumberOfPoles(), () => 0);
            for (let i = 0; i < numberOfPoles; i++) {
                handrailPoleTypeDropdowns.push(
                    <PortalDropdown key={"handrailpoletype_" + i}
                        placeholder={t`Paal` + " " + (i + 1).toString()}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailPoleTypesToSelectItems()}
                        onSelectionChange={(data) => {
                            let newHandrail = this.getHandrailInput();
                            if (newHandrail !== null) {
                                const newHandrailPoleTypes = [...newHandrail.handrailPoleTypes];
                                newHandrailPoleTypes[i] = data === null ? undefined : data.handrailPoleType;
                                newHandrail = newHandrail.setHandrailPoleTypes(newHandrailPoleTypes);

                                // Update pole finish
                                const pt: HandrailPoleTypeAPI = newHandrailPoleTypes[i] as HandrailPoleTypeAPI;
                                const hasFinish = isDefined(newHandrail.handrailPoleFinishes[i]);
                                if(!hasFinish && isDefined(pt) && pt.getDefaultHandrailPoleFinishId() != null){
                                    let newHandrailPoleFinishes = [...newHandrail.handrailPoleFinishes];
                                    newHandrailPoleFinishes[i] = this.props.quote.availableHandrailPoleFinishes.find(y => 
                                        y.getId().equals(pt.getDefaultHandrailPoleFinishId())
                                    );
                                    newHandrail = newHandrail.setHandrailPoleFinishes(newHandrailPoleFinishes);
                                }

                                this.updateHandrail(newHandrail);
                            }
                        }}
                        selectedId={inun(this.getHandrailInput()?.handrailPoleTypes[i], (a) => a.getSid(), () => null)}
                    />
                );
            }
            /**
             * Handrail pole finishes
             */
            const handrailPoleFinishesDropdowns = [];
            for (let i = 0; i < numberOfPoles; i++) {
                handrailPoleFinishesDropdowns.push(
                    <PortalDropdown key={"handrailpolefinish_" + i}
                        placeholder={t`Bovenafwerking` + " " + (i + 1).toString()}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailPoleFinishesToSelectItems()}
                        onSelectionChange={(data) => {
                            let newHandrail = this.getHandrailInput();
                            if (newHandrail !== null) {
                                const newHandrailPoleFinishes = [...newHandrail.handrailPoleFinishes];
                                newHandrailPoleFinishes[i] = data === null ? undefined : data.handrailPoleFinish;
                                newHandrail = newHandrail.setHandrailPoleFinishes(newHandrailPoleFinishes);
                                this.updateHandrail(newHandrail);
                            }
                        }}
                        selectedId={inun(this.getHandrailInput()?.handrailPoleFinishes[i], (a) => a.getSid(), () => null)}
                    />
                );
            }
            defaultHandrailPart.push(
                <div className="col-md-4" key={"handrailpoletypesandfinishes"}>
                    {(() => {
                        if (handrailSelected) {
                            return [
                                <SubTitle key="handrailselectedpaaltypes">Paaltypes</SubTitle>,
                                handrailPoleTypeDropdowns.length > 0 ?
                                    <div key="handrailselectedpaaltypesvalue" className="container-fluid" style={{padding: 0}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ValidityIndicator
                                                    {...this.state.validity.handrailPoleTypesValidity}
                                                >
                                                    {handrailPoleTypeDropdowns}
                                                </ValidityIndicator>
                                            </div>
                                            <div className="col-md-6">
                                                <ValidityIndicator
                                                    {...this.state.validity.handrailPoleFinishValidity}
                                                >
                                                    {handrailPoleFinishesDropdowns}
                                                </ValidityIndicator>
                                            </div>
                                        </div>
                                    </div> :
                                    <p key="handrailselectedpaaltypesselectinfo">Selecteer eerst een traptype en leuningtype.</p>

                            ]
                        }
                    })()}

                </div>
            );

            /**
             * Handrail supplements
             */
            defaultHandrailPart.push(
                <div className="col-md-4" key={3}>
                    {(() => {
                        if (handrailSelected) {
                            return [
                                <SubTitle key="handrailselectedsupplementssubtitle">{t`Supplementen leuning`}</SubTitle>,
                                <SupplementPicker
                                    key="supplementpicker"
                                    placeholder={t`Supplementen leuning`}
                                    availableSupplements={this.props.quote.availableSupplementsHandrail}
                                    selectedSupplements={this.getHandrail().supplements}
                                    onUpdateSupplements={(sup) => {
                                        this.updateSupplements(sup);
                                    }}
                                />,
                            ]
                        }
                    })()}

                </div>
            );
        }

        // ------------------------------------------------------------
        // Extra handrail
        // ------------------------------------------------------------
        let extraHandrailPart: React.ReactNode = "";
        const extraHandrailEnabled = ArticleInput.extraHandrailEnabled(this.props.article.article);

        const hr = this.getExtraHandrail();
        const extraHandrailPoleCount = hr ? hr.poleCount : 0;
        const extraHandrailTreadCount = hr ? hr.treadCount : 0;

        /**
         * Handrail pole types
         */
        const extraHandrailPoleTypeDropdowns = [];
        if(extraHandrailEnabled){
            for (let i = 0; i < extraHandrailPoleCount; i++) {
                extraHandrailPoleTypeDropdowns.push(
                    <PortalDropdown key={"extrahandrailpoletype_" + i}
                        placeholder={t`Paal` + " " + (i + 1).toString()}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailPoleTypesToSelectItems()}
                        onSelectionChange={(data) => {
                            let newHandrail = this.getExtraHandrailInput();
                            if (newHandrail !== null) {
                                const newHandrailPoleTypes = [...newHandrail.handrailPoleTypes];
                                newHandrailPoleTypes[i] = data === null ? undefined : data.handrailPoleType;
                                newHandrail = newHandrail.setHandrailPoleTypes(newHandrailPoleTypes);
    
                                // Update pole finish
                                const pt: HandrailPoleTypeAPI = newHandrailPoleTypes[i] as HandrailPoleTypeAPI;
                                const hasFinish = isDefined(newHandrail.handrailPoleFinishes[i]);
                                if(!hasFinish && isDefined(pt) && pt.getDefaultHandrailPoleFinishId() != null){
                                    let newHandrailPoleFinishes = [...newHandrail.handrailPoleFinishes];
                                    newHandrailPoleFinishes[i] = this.props.quote.availableHandrailPoleFinishes.find(y => 
                                        y.getId().equals(pt.getDefaultHandrailPoleFinishId())
                                    );
                                    newHandrail = newHandrail.setHandrailPoleFinishes(newHandrailPoleFinishes);
                                }
    
                                this.updateExtraHandrail(newHandrail);
                            }
                        }}
                        selectedId={inun(this.getExtraHandrailInput()?.handrailPoleTypes[i], (a) => a.getSid(), () => null)}
                    />
                );
            }
        }

        /**
         * Handrail pole finishes
         */
        const extraHandrailPoleFinishesDropdowns = [];
        if(extraHandrailEnabled){
            for (let i = 0; i < extraHandrailPoleCount; i++) {
                extraHandrailPoleFinishesDropdowns.push(
                    <PortalDropdown key={"extrahandrailpolefinish_" + i}
                        placeholder={t`Bovenafwerking` + " " + (i + 1).toString()}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailPoleFinishesToSelectItems()}
                        onSelectionChange={(data) => {
                            let newHandrail = this.getExtraHandrailInput();
                            if (newHandrail !== null) {
                                const newHandrailPoleFinishes = [...newHandrail.handrailPoleFinishes];
                                newHandrailPoleFinishes[i] = data === null ? undefined : data.handrailPoleFinish;
                                newHandrail = newHandrail.setHandrailPoleFinishes(newHandrailPoleFinishes);
                                this.updateExtraHandrail(newHandrail);
                            }
                        }}
                        selectedId={inun(this.getExtraHandrailInput()?.handrailPoleFinishes[i], (a) => a.getSid(), () => null)}
                    />
                );
            }
        }

        extraHandrailPart = 
        <span>
            <hr />
            <div className="col-md-4">
                <SubTitle>Extra leuning voorzien?</SubTitle>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={extraHandrailEnabled}
                    onChange={(selected) => {
                        if (selected && !ArticleInput.extraHandrailEnabled(this.props.article.article))
                            this.props.dispatch(enableExtraHandrail())
                    }}>Ja</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={!extraHandrailEnabled}
                    onChange={(selected) => {
                        if (selected) {
                            this.props.dispatch(disableExtraHandrail())
                        }
                    }}>Nee</PortalRadioButton>
                {extraHandrailEnabled ? (
                    <span>
                        <hr />
                        <SubTitle>Leuningtype</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.extraHandrail.typeValidity}
                        >
                            <PortalDropdown placeholder={t`Leuningtype`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.handrailTypesToSelectItems()}
                                onSelectionChange={async (data) => {
                                    let newHandrail = this.getExtraHandrailInput();
                                    newHandrail = newHandrail.setType(data === null ? null : data.type);

                                    if (newHandrail.type?.isExcludedFromPriceCalculations()) {
                                        await this.props.dispatch(disableExtraHandrail())
                                        return;
                                    }
                                    
                                    newHandrail = newHandrail.setSpindleThreadType(null);
                                    newHandrail = newHandrail.setSpindleThreadDescription(null);

                                    let warningDefaultExtraHandrailHandle: any = "";
                                    const newHandrailType: HandrailTypeAPI = newHandrail.type as HandrailTypeAPI;
                                    if (newHandrail.handle === null && newHandrailType !== null && newHandrailType.getDefaultHandrailHandleId() !== null) {
                                        warningDefaultExtraHandrailHandle = <Warning>Opgelet: de bijbehorende standaard handgreep werd toegepast.</Warning>;
                                        const handle: HandrailHandleAPI = this.getHandrailHandleById(newHandrailType.getDefaultHandrailHandleId());
                                        newHandrail = newHandrail.setHandle(handle);
                                    }

                                    this.updateExtraHandrail(newHandrail);

                                    this.setState({
                                        warningDefaultExtraHandrailHandle,
                                    })
                                }}
                                selectedId={inn(this.getExtraHandrail().type, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        {this.state.warningDefaultExtraHandrailHandle}
                        {extraHandrailSelected && (this.props.article.article?.extraHandrail?.type as HandrailTypeAPI)?.isShowSpindle() ? 
                        <>
                            <ValidityIndicator
                                {...this.state.validity.extraHandrail.spindleThreadTypeValidity}
                            >
                                <PortalDropdown placeholder={t`Type modelspijlen`}
                                    css={{ compose: PortalDropdownStyle }}
                                    items={spindleThreadTypesToSelectItems(this.props.quote.availableSpindleThreadTypes, (this.getExtraHandrail().type as HandrailTypeAPI).getSupportedSpindleIds())}
                                    onSelectionChange={(data) => {
                                        this.updateExtraHandrail(this.getExtraHandrailInput().setSpindleThreadType(data === null ? null : data.type));
                                    }}
                                    selectedId={inn(this.getExtraHandrail().spindleThreadType, (a) => a.getSid())}
                                />
                            </ValidityIndicator> 
                            <Input placeholder={t`Omschrijving modelspijlen`} onChange={(description) => this.updateExtraHandrail(this.getExtraHandrailInput().setSpindleThreadDescription(description))} onBlur={(description: string) => this.updateExtraHandrail(this.getExtraHandrailInput().setSpindleThreadDescription(description))} value={this.getExtraHandrail().spindleThreadDescription ?? ""}/>
                        </>: ""
                    }
                        
                        <SubTitle>Handgreep</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.extraHandrail.handrailHandleValidity}
                        >
                            <PortalDropdown placeholder={t`Handgreep`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.handrailHandlesToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateExtraHandrail(this.getExtraHandrailInput().setHandle(data === null ? null : data.handle));
                                }}
                                selectedId={inn(this.getExtraHandrail().handle, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        
                    </span>
                ) : ""}
            </div>
            <div className="col-md-4">
                {extraHandrailEnabled ? (
                    <span>
                        <SubTitle>Aantal tredes en palen</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.extraHandrail.treadCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal tredes`}
                                onBlur={(treadCount: string) => {
                                    this.updateExtraHandrail(this.getExtraHandrailInput().setTreadCount(parseInt(treadCount)));
                                }}
                                value={extraHandrailTreadCount} />
                        </ValidityIndicator>
                        <ValidityIndicator
                            {...this.state.validity.extraHandrail.poleCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal palen`}
                                onBlur={(pc: string) => {
                                    let newHandrail = this.getExtraHandrailInput();
                                    const poleCount = parseInt(pc);
                                    newHandrail = newHandrail.setPoleCount(poleCount);
                                    if(!isNaN(poleCount) && poleCount < newHandrail.handrailPoleTypes.length){
                                        newHandrail = newHandrail.setHandrailPoleTypes(newHandrail.handrailPoleTypes.slice(0, poleCount));
                                        newHandrail = newHandrail.setHandrailPoleFinishes(newHandrail.handrailPoleFinishes.slice(0, poleCount));
                                    }
                                    this.updateExtraHandrail(newHandrail);
                                }}
                                value={extraHandrailPoleCount} />
                        </ValidityIndicator>
                    </span>
                ) : ""
                }

            </div>
            <div className="col-md-4">
                {extraHandrailEnabled && extraHandrailPoleCount > 0 ? (
                    <span>
                        <SubTitle key="extrahandrailselectedpaaltypes">Paaltypes</SubTitle>
                        <div className="container-fluid" style={{padding: 0}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <ValidityIndicator
                                        {...this.state.validity.extraHandrail.handrailPoleTypesValidity}
                                    >
                                        {extraHandrailPoleTypeDropdowns}
                                    </ValidityIndicator>
                                </div>
                                <div className="col-md-6">
                                    <ValidityIndicator
                                        {...this.state.validity.extraHandrail.handrailPoleFinishValidity}
                                    >
                                        {extraHandrailPoleFinishesDropdowns}
                                    </ValidityIndicator>
                                </div>
                            </div>
                        </div> 
                    </span>
                ) : ""
                }

            </div>
        </span>;

        return (
            <div className={"container-fluid"}>
                <div className="row">
                    {defaultHandrailPart}
                </div>
                <div className="row">
                    {extraHandrailPart}
                </div>
                <div className="row">
                    {this.subRenderWallHandrailPart()}
                </div>
            </div>
        );
    }

    private subRenderWallHandrailPart(): React.ReactNode{
        const whr: IWallHandrailInput = this.getWallHandrail();
        const wallHandrailEnabled = ArticleInput.wallHandrailEnabled(this.props.article.article);

        return  <span>
            <hr />
            <div className="col-md-4">
                <SubTitle>Muurleuning voorzien?</SubTitle>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={wallHandrailEnabled}
                    onChange={(selected) => {
                        if (selected && !ArticleInput.wallHandrailEnabled(this.props.article.article))
                            this.props.dispatch(enableWallHandrail())
                    }}>Ja</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }}
                    value={!wallHandrailEnabled}
                    onChange={(selected) => {
                        if (selected) {
                            this.props.dispatch(disableWallHandrail())
                        }
                    }}>Nee</PortalRadioButton>
                {wallHandrailEnabled ? (
                    <span>
                        <hr />
                        <SubTitle>Leuningtype</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.wallHandrail.typeValidity}
                        >
                            <PortalDropdown placeholder={t`Leuningtype`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.wallHandrailTypesToSelectItems()}
                                onSelectionChange={async (data) => {
                                    let newHandrail = this.getWallHandrailInput();
                                    newHandrail = newHandrail.setType(data === null ? null : data.type);

                                    this.updateWallHandrail(newHandrail);
                                }}
                                selectedId={inn(whr.type, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        <PortalCheckbox css={{compose: PortalRadioButtonStyle}} value={whr.ignoreWoodType} onChange={(checked) => {
                            this.updateWallHandrail(this.getWallHandrailInput().setIgnoreWoodType(checked))
                        }}>Onafhankelijk van materiaaltype</PortalCheckbox>

                        <SubTitle>Lengte muurleuning</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.wallHandrail.lengthValidity}
                        >
                            <MappedInput placeholder={t`Lengte muurleuning (m)`}
                                onBlur={(length: string) => {
                                    let l = parseFloat(length);
                                    if(isNaN(l))
                                        l = 0;
                                    this.updateWallHandrail(this.getWallHandrailInput().setLength(l));
                                }}
                                value={whr.length} />
                        </ValidityIndicator>
                        
                    </span>
                ) : ""}
            </div>
            <div className="col-md-4">
                {wallHandrailEnabled ? (
                    <span>
                        <SubTitle>Type muurhaak</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.wallHandrail.hookValidity}
                        >
                            <PortalDropdown placeholder={t`Type muurhaak`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.hooksToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateWallHandrail(this.getWallHandrailInput().setHook(data === null ? null : data.type));
                                }}
                                selectedId={inn(whr.hook, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        
                        <SubTitle>Aantal muurhaken</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.wallHandrail.hookCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal muurhaken`}
                                onBlur={(hookCount: string) => {
                                    let l = parseInt(hookCount);
                                    if(isNaN(l))
                                        l = 0;
                                    this.updateWallHandrail(this.getWallHandrailInput().setHookCount(l));
                                }}
                                value={whr.hookCount} />
                        </ValidityIndicator>
                    </span>
                ) : ""
                }

            </div>
            <div className="col-md-4">
                {wallHandrailEnabled ? (
                    <span>
                        <SubTitle>{t`Verbinding: aantal knikken`}</SubTitle>
                        <ValidityIndicator
                            {...this.state.validity.wallHandrail.bendCountValidity}
                        >
                            <MappedInput placeholder={t`Aantal knikken`}
                                onBlur={(bendCount: string) => {
                                    let l = parseInt(bendCount);
                                    if(isNaN(l))
                                        l = 0;
                                    this.updateWallHandrail(this.getWallHandrailInput().setBendCount(l));
                                }}
                                value={whr.bendCount} />
                        </ValidityIndicator>
                    </span>
                ) : ""
                }

            </div>
        </span>;
    }

    public subRenderConcrete() {

        return this.subRenderWood(); // TODO: replace with concrete-specific input
    }

    public render() {
        // TODO: type of input (for example unitCount should be integer)
        // TODO: modelspijlen db lijst, of echt enkel nummers?

        const r = this.props.article.article.isWood ? this.subRenderWood() : this.subRenderConcrete();

        return (
            <StepTemplate pageType={PageType.HANDRAIL} title={t`leuning.`} next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/4"}>
                {r}
            </StepTemplate>
        );
    }
}
