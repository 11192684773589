import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import ApiController from "../ApiController";
import {default as HandrailType, IHandrailType} from "../../../../../shared/datastructures/staircasedata/HandrailType";
import ObjectID from "bson-objectid";

// TODO: too much code duplication
export default class HandrailTypeAPI extends ApiController<HandrailType> implements IHandrailType {

    public static getBaseEndpoint(): string{
        return "hotec/handrailtype";
    }

    public static getApiKey(): string{
        return "type";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const handrailType = new HandrailType(
            dictionary.price,
            dictionary.l1Factor,
            title,
            desc,
            dictionary.showSpindle,
            dictionary.excludedFromPriceCalculations,
            dictionary.defaultHandrailHandleId ? new ObjectID(dictionary.defaultHandrailHandleId) : null,
            dictionary.supportedSpindleIds.map(x => new ObjectID(x)),
            dictionary.imagePreviewIds,
        );
        this.setData(handrailType);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public getL1Factor(): number {
        return this.getData().getL1Factor();
    }
    
    public getDefaultHandrailHandleId(): ObjectID | null {
        return this.getData().getDefaultHandrailHandleId();
    }
    
    public getSupportedSpindleIds(): ObjectID[] {
        return this.getData().getSupportedSpindleIds();
    }

    public isShowSpindle(): boolean {
        return this.getData().isShowSpindle();
    }

    public isExcludedFromPriceCalculations(): boolean {
        return this.getData().isExcludedFromPriceCalculations();
    }

    public getImagePreviewIds(): string[] {
        return this.getData().getImagePreviewIds();
    }

    public async setImagePreviewIds(ids: string[]): Promise<void> {
        this.getData().setImagePreviewIds(ids);
        await this.updateProperty("imagePreviewIds", ids);
    }

    public async setShowSpindle(showSpindle: boolean): Promise<void> {
        this.getData().setShowSpindle(showSpindle);
        await this.updateProperty("showSpindle", showSpindle);
    }

    public async setExcludedFromPriceCalculations(excludedFromPriceCalculations: boolean): Promise<void> {
        this.getData().setExcludedFromPriceCalculations(excludedFromPriceCalculations);
        await this.updateProperty("excludedFromPriceCalculations", excludedFromPriceCalculations);
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public async setPrice(newPrice: number): Promise<void> {
        this.getData().setPrice(newPrice);
        await this.updateProperty("price", newPrice);
    }
    
    public async setL1Factor(newFactor: number): Promise<void> {
        this.getData().setL1Factor(newFactor);
        await this.updateProperty("l1Factor", newFactor);
    }
    
    public async setDefaultHandrailHandleId(handleId: ObjectID): Promise<void> {
        this.getData().setDefaultHandrailHandleId(handleId);
        await this.updateProperty("defaultHandrailHandleId", handleId);
    }
    
    public async setSupportedSpindleIds(spindleIds: ObjectID[]): Promise<void> {
        this.getData().setSupportedSpindleIds(spindleIds);
        await this.updateProperty("supportedSpindleIds", spindleIds);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

}
