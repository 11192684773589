import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import ApiController from "../ApiController";
import {
    IStaircaseShape,
    default as StaircaseShape
} from "../../../../../shared/datastructures/staircasedata/StaircaseShape";
import TypeDependentPrice from "../../../../../shared/datastructures/staircasedata/TypeDependentPrice";
import ObjectID from "bson-objectid";

// TODO: too much code duplication
export default class StaircaseShapeAPI extends ApiController<StaircaseShape> implements IStaircaseShape {

    public static getBaseEndpoint(): string{
        return "hotec/staircaseshape";
    }

    public static getApiKey(): string{
        return "shape";
    }

    public static getPluralApiKey(): string{
        return "shapes";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const typeDependentPrices = TypeDependentPrice.parseFromJson(dictionary.typeDependentPrices);

        const staircaseShape = new StaircaseShape(
            dictionary.price,
            title,
            desc,
            typeDependentPrices,
            dictionary.prohibitedHandrailTypeIds.map(x => new ObjectID(x)),
            dictionary.imagePreviewIds,
        );
        this.setData(staircaseShape);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public getTypeDependentPrices(): TypeDependentPrice[] {
        return this.getData().getTypeDependentPrices();
    }

    public getProhibitedHandrailTypeIds(): ObjectID[] {
        return this.getData().getProhibitedHandrailTypeIds();
    }

    public isProhibitedHandrailTypeId(id: ObjectID): boolean{
        return this.getProhibitedHandrailTypeIds().findIndex(x => x.toHexString() === id.toHexString()) > -1;
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public async setPrice(newPrice: number): Promise<void> {
        this.getData().setPrice(newPrice);
        await this.updateProperty("price", newPrice);
    }

    public async setTypeDependentPrices(prices: TypeDependentPrice[]): Promise<void> {
        this.getData().setTypeDependentPrices(prices);
        await this.updateProperty("typeDependentPrices", prices.map(x=>x.asDict()));
    }

    public async setProhibitedHandrailTypeIds(ids: ObjectID[]): Promise<void> {
        this.getData().setProhibitedHandrailTypeIds(ids);
        await this.updateProperty("prohibitedHandrailTypeIds", ids);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public getImagePreviewIds(): string[] {
        return this.getData().getImagePreviewIds();
    }
    
    public async setImagePreviewIds(newImagePreviewIds: string[]): Promise<void> {
        this.getData().setImagePreviewIds(newImagePreviewIds);
        await this.updateProperty("imagePreviewIds", newImagePreviewIds);
    }

}
