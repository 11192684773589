import * as React from "react";
import { connect, DispatchProp } from "react-redux";
import { setArticleShape, setArticleTransportation } from "../../../actions/articleCreationActions";
import SubTitle from "../../../components/SubTitle";
import StepTemplate from "../StepTemplate";
import * as PortalDropdownStyle from "../../../../css/PortalDropdown.css";
import { inn } from "orbiter-core/src/basic";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import IArticleInput, { IShapeInput, ITransportationInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import ShapeInput from "../../../../../../shared/datastructures/articleinput/ShapeInput";
import { INPUT_NOT_DEFINED, INVALID_TYPE, PROHIBITED_SELECTION, TOO_SMALL_VALUE } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import {t} from "ttag";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { IThickness } from "../../../../../../shared/datastructures/staircasedata/Thickness";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { SupplementAPI } from "../../../apicontroller/staircasedata/SupplementAPI";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import { RiserThicknessAPI, StringerThicknessAPI, TreadThicknessAPI } from "../../../apicontroller/staircasedata/ThicknessAPI";
import { IStaircaseShape } from "../../../../../../shared/datastructures/staircasedata/StaircaseShape";
import StaircaseShapeAPI from "../../../apicontroller/staircasedata/StaircaseShapeAPI";
import TransportationInput, { PLACEMENT } from "../../../../../../shared/datastructures/articleinput/TransportationInput";
import { IBaseConstantSet } from "../../../../../../shared/datastructures/staircasedata/BaseConstantSet";
import ObjectID from "bson-objectid";
import Warning from "../../../components/Overview/Warning";
import SupplementPicker from "../../../components/SupplementPicker";
import ImagePreview from "../../../components/ImagePreview";

const LARGE_THICKNESS_THRESHOLD = 40;

type state = {validity:any, warningLargeTreadThicknessSupplementApplied: React.ReactNode, warningLargeStringerThicknessSupplementApplied: React.ReactNode, minTreadThickness: number, minRiserThickness: number, minStringerThickness: number, allowedStaircaseShapes: (IStaircaseShape & IDatabaseController)[]};

@(connect((store: any) => {
    return {article: {...store.articleCreation}, quote: {...store.quoteCreation}};
}) as any)
export default class Shape extends React.Component<{article: IArticleCreationState, quote: IQuoteCreationState} & DispatchProp, state> {

    private updateArticleShape(shape: IShapeInput) {
        this.props.dispatch(setArticleShape(shape));
    }

    private getShapeInput(): ShapeInput{
        return ShapeInput.from(this.props.article.article.shape);
    }

    private getShape(): IShapeInput{
        return this.props.article.article.shape;
    }

    private getTransportation(): ITransportationInput{
        return this.props.article.article.transportation;
    }

    private getTransportationInput(): TransportationInput{
        return TransportationInput.from(this.props.article.article.transportation);
    }

    private updatePlacementSupplements(supplements){
        const newTransportation = this.getTransportationInput().setPlacementSupplements(supplements);
        (this.props as any).dispatch(setArticleTransportation(newTransportation));
    }

    private addPlacementSupplement(supplement: SupplementAPI){
        let count =  1;
        let newSupplements = this.getTransportation().placementSupplements.filter(i => {
            if(i.supplement.getId() === supplement.getId()){ // TODO: sid?
                count += i.amount;
                return false;
            }
            else return true;
        }).map(i => i /* TODO: clone */);
        newSupplements.push({
            amount: count,
            supplement /* TODO: clone */
        });
        this.updatePlacementSupplements(newSupplements);
    }

    private addPlacementSupplementById(id: ObjectID){
        for(const sup of this.props.quote.availableSupplementsPlacement){
            if(sup.getId().toHexString() === id.toHexString()){
                this.addPlacementSupplement(sup);
                return;
            }
        }
    }

    private addLargeThicknessSupplementIfNeeded(thickness: IThickness | null | undefined, treadThickness: boolean){

        const currentStringerThickness = this.getShape()?.stringerThickness?.getThickness() ?? 0;
        const currentTreadThickness = this.getShape()?.treadThickness?.getThickness() ?? 0;

        const otherThickness = treadThickness ? currentStringerThickness : currentTreadThickness;
        if(thickness && thickness.getThickness() >= LARGE_THICKNESS_THRESHOLD && this.getArticle().transportation.transportationMethod === PLACEMENT && otherThickness < LARGE_THICKNESS_THRESHOLD){
            const supplementId = (this.getArticle()?.articleInfoStore?.getBaseConstantSet() as IBaseConstantSet)?.getDefaultLargeThicknessSupplementId();
            if(supplementId){
                this.addPlacementSupplementById(supplementId);
                const wrning = <Warning>{t`Opgelet: deze dikte is groter dan {}, er werd automatisch een supplement toegevoegd onder plaatsing.`.replace('{}', LARGE_THICKNESS_THRESHOLD.toString())}</Warning>;
                if(treadThickness){
                    this.setState({
                        warningLargeTreadThicknessSupplementApplied: wrning
                    });
                }else{
                    this.setState({
                        warningLargeStringerThicknessSupplementApplied: wrning
                    });
                }
                    
            }
        }
    }

    private getArticle(): IArticleInput {
        return this.props.article.article;
    }

    public staircaseShapesToSelectItems() {
        const list = [];
        for (const shape of this.state.allowedStaircaseShapes) {
            list.push(
                {
                    id: shape.getSid(),
                    title: dt(shape.getTitle()),
                    staircaseShape: shape,
                    preview: <span>{dt(shape.getTitle())} <ImagePreview imagePreviewIds={shape.getImagePreviewIds()} description={shape.getDescription()} /></span>,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    public treadThicknessToSelectItems() {
        const list: {id: string, title: string, treadThickness: TreadThicknessAPI}[] = [];
        for (const thickness of this.props.quote.availableTreadThickness) {
            list.push(
                {
                    id: thickness.getSid(),
                    title: dt(thickness.getTitle()),
                    treadThickness: thickness,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1).filter(x => x.treadThickness.getThickness() >= this.state.minTreadThickness || x.treadThickness.getSid() === this.getShape().treadThickness?.getSid());
    }

    public riserThicknessToSelectItems() {
        const list: {id: string, title: string, riserThickness: RiserThicknessAPI}[] = [];
        for (const thickness of this.props.quote.availableRiserThickness) {
            list.push(
                {
                    id: thickness.getSid(),
                    title: dt(thickness.getTitle()),
                    riserThickness: thickness,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1).filter(x => x.riserThickness.getThickness() >= this.state.minRiserThickness || x.riserThickness.getSid() === this.getShape().getRiserThickness()?.getSid());
    }

    public stringerThicknessToSelectItems() {
        const list: {id: string, title: string, stringerThickness: StringerThicknessAPI}[] = [];
        for (const thickness of this.props.quote.availableStringerThickness) {
            list.push(
                {
                    id: thickness.getSid(),
                    title: dt(thickness.getTitle()),
                    stringerThickness: thickness,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1).filter(x => x.stringerThickness.getThickness() >= this.state.minStringerThickness || x.stringerThickness.getSid() === this.getShape().stringerThickness?.getSid());
    }

    public subRenderWood() {
        return this.subRender(
            <span>
                <SubTitle>{t`Open of gesloten trap`}</SubTitle>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }} value={!this.getShape().closedStaircase}
                    onChange={() => {if(this.getShapeInput().closedStaircase !== false) this.updateArticleShape(this.getShapeInput().setClosedStaircase(false))}}>{t`Open trap`}</PortalRadioButton>
                <PortalRadioButton css={{ compose: PortalRadioButtonStyle }} value={this.getShape().closedStaircase}
                    onChange={() => {if(this.getShapeInput().closedStaircase !== true) this.updateArticleShape(this.getShapeInput().setClosedStaircase(true).setRiserCount(null))}}>{t`Gesloten trap`}</PortalRadioButton>
            </span>
        )
    }

    private async doValidityChecks(nextProps: IArticleCreationState){
        /**
         * Validity checks
         */
        const enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.SHAPE); // Validity check empty allowed
        const treadThicknessValidity = await new ValidityErrorCatcher(() => ShapeInput.treadThicknessValidityCheck(nextProps.article.shape.treadThickness, nextProps.article))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(true, TOO_SMALL_VALUE, t`De gekozen dikte moet minimaal gelijk zijn aan ` + await ShapeInput.getMinTreadThickness(nextProps.article) + "mm.")
            .catchError();
        const stringerThicknessValidity = await new ValidityErrorCatcher(() => ShapeInput.stringerThicknessValidityCheck(nextProps.article.shape.stringerThickness, nextProps.article))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(true, TOO_SMALL_VALUE, t`De gekozen dikte moet minimaal gelijk zijn aan ` + await ShapeInput.getMinStringerThickness(nextProps.article) + "mm.")
            .catchError();
        const riserThicknessValidity = await new ValidityErrorCatcher(async () => await ShapeInput.riserThicknessValidityCheck(nextProps.article.shape.getRiserThickness(), await nextProps.article.shape.getRiserThickness(), nextProps.article))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(true, TOO_SMALL_VALUE, t`De gekozen dikte moet minimaal gelijk zijn aan ` + await ShapeInput.getMinRiserThickness(nextProps.article) + "mm.")
            .catchError();
        const shapeValidity = await new ValidityErrorCatcher(async () => await ShapeInput.staircaseShapeValidityCheck(nextProps.article.shape.staircaseShape, nextProps.article))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(true, PROHIBITED_SELECTION, t`Deze trapvorm kan niet gebruikt worden in combinatie met de geselecteerde leuning, extra leuning, of overloopleuning.`)
            .catchError();
        const riserCountValidity = await new ValidityErrorCatcher(() => ShapeInput.riserCountValidityCheck(nextProps.article.shape))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(enableEmptyCheck, INVALID_TYPE, t`Geef een positief getal in.`)
            .catchError()


        this.setState({validity:{
            treadThicknessValidity,
            stringerThicknessValidity,
            riserThicknessValidity,
            shapeValidity,
            riserCountValidity,
        }})
    }

    componentDidMount(){
        this.runUpdate(this.props);
    }

    runUpdate(props){
        const article: IArticleCreationState = props.article;
        this.doValidityChecks(article);
        (async () => {

            const allowedStaircaseShapes: StaircaseShapeAPI[] = [];
            for (let i = 0; i < props.quote.availableStaircaseShapes.length; i++) {
                const shape: StaircaseShapeAPI = props.quote.availableStaircaseShapes[i];
                if(shape.getSid() === this.getShape().staircaseShape?.getSid() || ! await ShapeInput.isProhibitedShape(shape, article.article)){
                    allowedStaircaseShapes.push(shape);
                }
            }

            this.setState({
                minTreadThickness : await ShapeInput.getMinTreadThickness(article.article),
                minRiserThickness : await ShapeInput.getMinRiserThickness(article.article),
                minStringerThickness : await ShapeInput.getMinStringerThickness(article.article),
                allowedStaircaseShapes: allowedStaircaseShapes,
            })

        })()
    }

    componentWillReceiveProps(nextProps){
        this.runUpdate(nextProps);
    }

    constructor(a){
        super(a);
        this.state = {
            warningLargeTreadThicknessSupplementApplied: "",
            warningLargeStringerThicknessSupplementApplied: "",
            validity: {},
            minTreadThickness: 0,
            minRiserThickness: 0,
            minStringerThickness: 0,
            allowedStaircaseShapes: [],
        }
    }

    public subRender(addedOptions?) {
        
        return (
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col-md-4">

                        {addedOptions}

                        <SubTitle>{t`Algemene informatie`}</SubTitle>

                        <ValidityIndicator 
                            {...this.state.validity.treadThicknessValidity}
                        >
                            <PortalDropdown placeholder={"Dikte tredes (mm)"}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.treadThicknessToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateArticleShape(this.getShapeInput().setTreadThickness(data === null ? null : data.treadThickness));
                                    this.addLargeThicknessSupplementIfNeeded(data?.treadThickness, true);
                                }}
                                selectedId={inn(this.getShape().treadThickness, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        {this.state.warningLargeTreadThicknessSupplementApplied}

                        <ValidityIndicator                             
                            {...this.state.validity.stringerThicknessValidity}
                        >
                            <PortalDropdown placeholder={t`Dikte wangen (mm)`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.stringerThicknessToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateArticleShape(this.getShapeInput().setStringerThickness(data === null ? null : data.stringerThickness));
                                    this.addLargeThicknessSupplementIfNeeded(data?.stringerThickness, false);
                                }}
                                selectedId={inn(this.getShape().stringerThickness, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        {this.state.warningLargeStringerThicknessSupplementApplied}

                        {(() => {
                            if(this.getShape().closedStaircase === false){
                                return <SubTitle>{t`Tegentreden`}</SubTitle>;
                            }
                        })()}                        

                        <ValidityIndicator
                            {...this.state.validity.riserThicknessValidity}
                        >
                            <PortalDropdown placeholder={t`Dikte tegentreden (mm)`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.riserThicknessToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateArticleShape(this.getShapeInput().setRiserThickness(data === null ? null : data.riserThickness));
                                }}
                                selectedId={inn(this.getShape().getRiserThickness(), (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                        
                        {(()=>{
                            if(this.getShape().closedStaircase === false){
                                return  <ValidityIndicator
                                            {...this.state.validity.riserCountValidity}
                                        >
                                            <MappedInput placeholder={t`Aantal tegentreden`}
                                                onBlur={(riserCount: string) => {
                                                    this.updateArticleShape(this.getShapeInput().setRiserCount(parseInt(riserCount)));
                                                }}
                                                value={this.getShape().riserCount ?? ''} />
                                        </ValidityIndicator>
                            }
                        })()}

                    </div>
                    <div className="col-md-4">
                        <SubTitle>Trapvorm</SubTitle>
                        <ValidityIndicator 
                            {...this.state.validity.shapeValidity}
                        >
                            <PortalDropdown placeholder={t`Trapvorm`}
                                css={{ compose: PortalDropdownStyle }}
                                items={this.staircaseShapesToSelectItems()}
                                onSelectionChange={(data) => {
                                    this.updateArticleShape(this.getShapeInput().setStaircaseShape(data === null ? null : data.staircaseShape));
                                }}
                                selectedId={inn(this.getShape().staircaseShape, (a) => a.getSid())}
                            />
                        </ValidityIndicator>
                    </div>
                    <div className="col-md-4">
                        <SubTitle>{t`Supplementen treden & wangen`}</SubTitle>
                        <SupplementPicker
                            placeholder={t`Supplementen treden & wangen`}
                            availableSupplements={this.props.quote.availableSupplementsTread}
                            selectedSupplements={this.getShape().treadSupplements}
                            onUpdateSupplements={(sup) => {
                                const newShape = this.getShapeInput().setTreadSupplements(sup);
                                this.updateArticleShape(newShape);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    public subRenderConcrete() {
        return this.subRender();
    }

    public render() {
        // TODO: type of input (for example unitCount should be integer)

        let subRender;
        if (this.props.article.article.isWood) {
            subRender = this.subRenderWood();
        } else {
            subRender = this.subRenderConcrete();
        }

        return (
            <StepTemplate pageType={PageType.SHAPE} title={t`trapvorm.`} next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/3"}>
                {subRender}
            </StepTemplate>
        );
    }
}
