import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import TreatmentType, {ITreatmentType} from "../../../../../shared/datastructures/staircasedata/TreatmentType";
import ApiController from "../ApiController";

export default class TreatmentTypeAPI extends ApiController<TreatmentType> implements ITreatmentType {

    public static getBaseEndpoint(): string{
        return "hotec/treatmenttype";
    }

    public static getApiKey(): string{
        return "type";
    }

    public static getPluralApiKey(): string{
        return "types";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const tt = new TreatmentType(
            dictionary.staircaseFactor,
            dictionary.handrailFactor,
            dictionary.landingStageFactor,
            dictionary.wallHandrailFactor,
            title,
            desc,
            dictionary.imagePreviewIds,
        );
        this.setData(tt);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getStaircaseFactor(): number {
        return this.getData().getStaircaseFactor();
    }

    public getHandrailFactor(): number {
        return this.getData().getHandrailFactor();
    }
    
    public getLandingStageFactor(): number {
        return this.getData().getLandingStageFactor();
    }
    
    public getWallHandrailFactor(): number {
        return this.getData().getWallHandrailFactor();
    }

    public getImagePreviewIds(): string[] {
        return this.getData().getImagePreviewIds();
    }

    public async setImagePreviewIds(newImagePreviewIds: string[]): Promise<void> {
        this.getData().setImagePreviewIds(newImagePreviewIds);
        await this.updateProperty("imagePreviewIds", newImagePreviewIds);
    }

    public async setStaircaseFactor(newFactor: number): Promise<void> {
        this.getData().setStaircaseFactor(newFactor);
        await this.updateProperty("staircaseFactor", newFactor);
    }

    public async setHandrailFactor(newFactor: number): Promise<void> {
        this.getData().setHandrailFactor(newFactor);
        await this.updateProperty("handrailFactor", newFactor);
    }

    public async setLandingStageFactor(newFactor: number): Promise<void> {
        this.getData().setLandingStageFactor(newFactor);
        await this.updateProperty("landingStageFactor", newFactor);
    }

    public async setWallHandrailFactor(newFactor: number): Promise<void> {
        this.getData().setWallHandrailFactor(newFactor);
        await this.updateProperty("wallHandrailFactor", newFactor);
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

}
